import { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme } from "client-v2";

import {
	Button,
	ColorV2,
	Text,
	Counter,
} from "@adoptaunabuelo/react-components";
import { Pencil } from "lucide-react";
import VideoModal from "../../../components/modal/VideoModal";
import SectionContainer from "./SectionContainer";

const isScreen01: any = "1350px";
const isScreen02: any = "1050px";

const SectionContainerStyled = styled(SectionContainer)`
	width: 489px;
	margin-right: 8%;
	justify-content: center;
	${media.lessThan(isScreen01)`
        width: 425px;
        margin-right: 6%;
    `}
	${media.lessThan(isScreen02)`
        width: 375px;
        margin-right: 4%;
    `}
	${media.lessThan("medium")`
		width: unset;
		margin-right: 0px;
	`}
`;
const ButtonStyled = styled(Button)`
	${media.lessThan("medium")`
        width: 100%;
    `}
`;
const StampsContainer = styled.div`
	position: relative;
	margin-bottom: 38px;
`;
const StampView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 132px;
	height: 110px;
	background-size: cover;
`;
const StampLogo = styled.img`
	width: 90px;
	height: 54px;
	object-fit: contain;
`;
const AUAStampLogo = styled.img`
	position: absolute;
	top: 32px;
	left: -40px;
	width: 98px;
	transform: rotate(10deg);
`;

const Home = (props: Props) => {
	const [showVideo, setShowVideo] = useState(false);

	return (
		<>
			<VideoModal
				isVisible={showVideo}
				url={"https://www.youtube.com/watch?v=nalM0N80OYA"}
				onFinish={() => setShowVideo(false)}
				onClose={() => setShowVideo(false)}
			/>
			<SectionContainerStyled animated={props.animated}>
				<StampsContainer>
					<StampView
						style={{
							backgroundImage: `url("./assets/letter/stamp_empty.png")`,
						}}
					>
						<StampLogo src={props.corporate.logo?.url} />
					</StampView>
					<AUAStampLogo src={"./assets/letter/stamp_aua.png"} />
				</StampsContainer>
				<Text type="h2" weight="semibold" style={{ marginBottom: 48 }}>
					Esta Navidad, ¡la felicidad llega en un sobre de mano de{" "}
					{props.corporate.name}!
				</Text>
				<Counter
					amount={props.amount}
					color={props.corporate.color?.main}
					height={53}
					width={36}
					fontSize={40}
				/>
				<Text type="p" style={{ marginTop: 8, marginBottom: 48 }}>
					Cartas enviadas
				</Text>
				<ButtonStyled
					style={{
						backgroundColor: props.corporate.color?.main,
						color: props.corporate.color?.third,
					}}
					icon={
						<Pencil
							color={props.corporate.color?.third}
							height={20}
							width={20}
						/>
					}
					onClick={props.onFinish}
				>
					Escribir mi carta
				</ButtonStyled>
			</SectionContainerStyled>
		</>
	);
};
export default Home;
export interface Props {
	corporate: CoorporateScheme;
	amount: number;
	animated?: boolean;
	onFinish: () => void;
}
