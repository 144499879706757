export enum ActionType {
	SHOW_ERROR = "SHOW_ERROR",
	SHOW_LOADING = "SHOW_LOADING",
	SHOW_SUCCESS = "SHOW_SUCCESS",
	APP_TRANSLATION = "APP_TRANSLATION",
	APP_LOCATION = "APP_LOCATION",
	CURRENT_USER = "CURRENT_USER",
	SET_IS_SCREEN01 = "SET_IS_SCREEN01",
	SET_IS_SCREEN02 = "SET_IS_SCREEN01",
	SET_IS_MOBILE = "SET_IS_MOBILE",
}
