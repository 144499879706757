import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Volunteer,
	Track,
	PaymentMethod,
	Challenge,
	ChallengeScheme,
	UserScheme,
	PaymentMethodScheme,
} from "client-v2";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Signature, { SignatureRef } from "../../components/section/Signature";
import Price, { PriceRef } from "../../components/section/Price";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import ResumeCheckout from "../challenge/section/ResumeCheckout";
import Wizard from "../../components/navigation/Wizard";
import SkipModal from "../../components/modal/SkipPaymentModal";
import Success from "./section/Success";
import SignatureConfirmationModal from "../../components/modal/SignatureConfirmationModal";
import Donation from "../../components/section/Donation";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError, setShowSuccess, setShowLoading } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const currentUser = useSelector(
		(state: State) => state.user.currentUser
	);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const appLocation = useSelector((state: State) => state.location.location);

	const signatureStep = useRef<SignatureRef>(null);
	const priceStep = useRef<PriceRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [amount, setAmount] = useState(15);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState<any>({});
	const [step, setStep] = useState(0);
	const [completed, setCompleted] = useState(false);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [showSignatureConfirmation, setShowSignatureConfirmation] =
		useState(false);
	const [isSubscriptor, setIsSubscriptor] = useState(false);

	useEffect(() => {
		if (location.state && location.state.challenge) {
			setShowLoading(true);
			setChallenge(location.state.challenge);
			const restAmount =
				location.state.challenge.goal - location.state.challenge.amount;
			if (restAmount <= 0) setCompleted(true);
			if (currentUser) {
				Volunteer.isSubscriptor(currentUser.objectId).then(
					(result) => {
						setIsSubscriptor(result.data);
						setShowLoading(false);
					}
				);
			} else {
				setShowLoading(false);
			}
		} else {
			navigate("/");
		}
	}, []);

	const onNextClick = async () => {
		//Signature
		if (step === 0) {
			const newData = signatureStep.current?.getData();
			if (newData?.user) {
				const idCard = newData.user.idCard;
				const city = newData.user.city;
				if (idCard && city && challenge) {
					signChallenge(challenge, newData.user, newData.anonymous);
				} else
					setShowError({
						show: true,
						message: "Completa todos los datos antes de continuar",
					});
			} else {
				setShowError({
					show: true,
					message: "Completa todos los datos antes de continuar",
				});
			}
		}
		//Price
		else if (step === 1) {
			const newData = priceStep.current?.getData();
			if (newData && newData.price && newData.charge && currentUser) {
				setLoading(true);
				const result = await Donation.getProduct({
					user: currentUser,
					price: newData.price,
					charge: newData.charge,
					view: "signature",
				});
				setPaymentsMethods(result.paymentMethods);
				setData({
					...data,
					product: result.product,
					paymentMethod: result.defaultPaymentMethod,
				});
				setLoading(false);
				setStep(step + 1);
			}
		}
		//Checkout
		else if (step === 2) {
			setLoading(true);
			const newData = await paycardsStep.current?.getData();
			setLoading(false);
			//Paycard
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (currentUser) {
					initSubscription(currentUser, newData.paymentMethod);
				}
			}
			//Apple Pay
			else if (newData && newData.paymentRequest) {
				newData.paymentRequest.show();
				newData.paymentRequest.on("paymentmethod", async (ev) => {
					setData({
						...data,
						paymentMethod: {
							id: ev.paymentMethod.id,
							brand: "apple_pay",
						},
					});
					if (currentUser) {
						initSubscription(currentUser, ev.paymentMethod, ev);
					}
				});
			}
			//Sepa Debit
			else if (newData && newData.paymentMethod) {
				if (currentUser) {
					initSubscription(currentUser, {
						id: newData.paymentMethod.objectId,
						brand: "sepa_debit",
					});
				}
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async (result: any) => {
						if (currentUser) {
							const result = await Donation.getPaycards(
								currentUser.objectId,
								"one"
							);
							setPaymentsMethods(result.paymentMethods);
							setData({
								...data,
								paymentMethod: result.defaultPaymentMethod,
							});
							setLoading(false);
						}
					})
					.catch((error: string) => {
						setLoading(false);
					});
			}
		}
	};

	const signChallenge = async (
		challenge: ChallengeScheme,
		user: UserScheme,
		anonymous: boolean
	) => {
		Challenge.initSignature(challenge?.objectId, {
			userId: user.objectId,
			anonymous: anonymous,
		});
		//Track on mixpanel
		Track.track("donation progress init", {
			view: "signature",
		});
		setShowSignatureConfirmation(true);
	};

	const initSubscription = async (
		user: UserScheme,
		paymentMethod: any,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		const result = await Donation.initSubscription({
			user: user,
			product: data.product,
			currency: (appLocation && appLocation.currency) ? appLocation.currency.name : "eur",
			paymentMethod: paymentMethod,
			paymentRequestEvent: paymentRequestEvent,
			view: "web-donation",
		});
		//Error
		if (result.error) {
			setShowError({ show: true, message: result.error });
			setLoading(false);
		}
		//Need 3d secure confirmation
		else if (result.result) {
			paycardsStep.current?.confirmPaymentIntent(result.result);
		}
		//Finish confirmation
		else {
			setSuccess(true);
			setLoading(false);
		}
	};

	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/firma/?id=" + challenge?.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		} else if (op.id === "url") {
			navigator.clipboard.writeText(url);
			setShowSuccess({
				show: true,
				message: "Se ha copiado el enlace en tu portapapeles",
			});
		}
	};

	return (
		<>
			{challenge && currentUser && (
				<SignatureConfirmationModal
					isVisible={showSignatureConfirmation}
					challenge={challenge}
					user={currentUser}
					onClose={() => {
						setStep(step + 1);
						setShowSignatureConfirmation(false);
					}}
				/>
			)}
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				options={[
					{
						title: (
							<span>
								En España más de{" "}
								<span style={{ fontWeight: 600 }}>
									2 millones de mayores viven solos
								</span>{" "}
								y muchos de ellos no reciben visitas.
							</span>
						),
					},
					{
						title: (
							<span>
								<span style={{ fontWeight: 600 }}>
									Cada euro cuenta
								</span>
								. Con una pequeña aportación económica también
								puedes generar un gran impacto.
							</span>
						),
					},
					{
						title: (
							<span>
								Gracias a tu colaboración{" "}
								<span style={{ fontWeight: 600 }}>
									ningún abuelo tiene que pagar nada
								</span>
								. Los mayores reciben acompañamiento semanal y
								participan en nuestras actividades de forma
								gratuita.
							</span>
						),
					},
				]}
				onClick={() => {
					Track.track("skip donation modal click", {
						view: "signature",
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.track("skip donation modal close", {
						view: "signature",
					});
					setShowSkipModal(false);
					setStep(step + 2);
				}}
			/>
			<Wizard
				tag={"register-signature"}
				location={appLocation}
				loginIndex={0}
				step={step}
				titles={[
					"Tu firma",
					"Gracias por firmar, " +
						currentUser?.name +
						".\n¿Podrías colaborar con algo más?",
					data.product?.charge === "one"
						? "Método de pago"
						: "Cuenta bancaria",
				]}
				subtitles={[
					"Revisa que tus datos sean correctos.",
					"",
					data.product?.charge === "one"
						? "Introduce el método de pago con el que realizarás la donación."
						: "Introduce el IBAN donde quieres que domiciliemos tu donación.",
				]}
				onError={(error: string) =>
					setShowError({ show: true, message: error })
				}
				onStepChange={(step: number) => {
					setStep(step);
				}}
				buttonProps={{
					loading: loading,
					success: success,
					onClick: onNextClick,
					onSuccess: () => setStep(step + 1),
				}}
				skipButtonProps={
					step === 1
						? {
								children: "No quiero colaborar",
								onClick: () => {
									Track.track("skip donation modal show", {
										view: "signature",
									});
									setShowSkipModal(true);
								},
						  }
						: undefined
				}
				RightViews={
					challenge
						? [<ResumeCheckout challenge={challenge} />, undefined]
						: []
				}
				LeftViews={[
					<Signature ref={signatureStep} user={currentUser} />,
					<Price
						ref={priceStep}
						currency={"eur"}
						language={"es"}
						hideTitle={true}
						hideSubtitle={true}
						options={
							isSubscriptor
								? ["one"]
								: ["monthly", "annually", "one"]
						}
						defaultPrice={15}
						challenge={challenge}
						onPriceChange={(amount) => setAmount(amount)}
					/>,
					<Paycards
						ref={paycardsStep}
						paymentMethods={paymentMethods}
						buttonProps={{
							children:
								data.paymentMethod?.brand === "apple_pay" ||
								data.paymentMethod?.brand === "google_pay"
									? "Colaborar con"
									: "Colaborar con " +
									  data.product?.price +
									  " €" +
									  (data.product?.charge === "monthly"
											? "/mes"
											: data.product?.charge ===
											  "annually"
											? "/año"
											: ""),
							width: "100%",
							request: data.paymentMethod?.brand,
						}}
						currency={"eur"}
						countryCode={"ES"}
						amount={amount}
						currentUser={currentUser}
						charge={
							data.product?.charge ? data.product.charge : "one"
						}
						onPaymentMethodClick={onPaymentMethodClick}
						onSetupConfirmed={(
							paymentMethodId?: string,
							error?: string
						) => {
							if (error) {
								setShowError({ show: true, message: error });
								setLoading(false);
							} else {
								setSuccess(true);
								setLoading(false);
							}
						}}
					/>,
					<Success onShareClick={onShareClick} />,
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {}
