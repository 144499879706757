import { UserScheme } from "client-v2";
import styled from "styled-components";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import moment from "moment";
import media from "styled-media-query";

const screen01: any = "1350px";
const screen02: any = "1050px";
const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 286px;
	padding-top: 32px;
	margin-right: 38px;
	${media.lessThan(screen01)`
		width: 250px;
		margin-right: 32px;
    `}
	${media.lessThan(screen02)`
        width: 220px;
		margin-right: 16px;
    `}
	${media.lessThan("medium")`
        display: none;
    `}
`;
const GrandpaContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const GrandpaImage = styled.img`
	height: 160px;
	width: 160px;
	object-fit: cover;
`;
const GrandpaDataContainer = styled.div`
	background-color: white;
	margin-top: 16px;
	width: 224px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
`;
const GrandpaData = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px;
	gap: 8px;
`;

const LetterLeft = (props: LetterLeftProps) => {
	return (
		<Container>
			<GrandpaContainer>
				<GrandpaImage
					src={
						props.grandpa.image
							? props.grandpa.image.url
							: props.grandpa.gender
							? props.grandpa.gender === "male"
								? require("../../../assets/images/icon/grandpa_icon.png")
								: props.grandpa.gender === "female"
								? require("../../../assets/images/icon/grandma_icon.png")
								: require("../../../assets/images/icon/user_icon.png")
							: require("../../../assets/images/icon/user_icon.png")
					}
				/>
				<GrandpaDataContainer>
					<GrandpaData>
						<Text type="h5" weight="semibold">
							{props.grandpa.name}
						</Text>
						{props.grandpa.birthday && (
							<Text type="p2">
								{moment().diff(
									props.grandpa.birthday?.iso,
									"years"
								)}{" "}
								años - {moment(props.grandpa.birthday.iso).format("DD MMM YYYY")}
							</Text>
						)}
						<Text type="p2">{props.grandpa.shortAddress}</Text>
					</GrandpaData>
				</GrandpaDataContainer>
			</GrandpaContainer>
		</Container>
	);
};
export default LetterLeft;
export interface LetterLeftProps {
	grandpa: UserScheme;
}
