import styled from "styled-components";
import SectionContainer from "./SectionContainer";
import { Button, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { PieChart } from "react-minimal-pie-chart";
import media from "styled-media-query";
import LetterIcon from "../../../assets/images/letter/Icon";

const isScreen01: any = "1350px";
const isScreen02: any = "1050px";
const Container = styled(SectionContainer)`
	margin-left: 8%;
	width: 485px;
	justify-content: center;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	background-color: white;
	border-radius: 24px 24px 0px 0px;
	padding: 40px 32px;
	${media.lessThan(isScreen01)`
        width: 425px;
        margin-left: 6%;
    `}
	${media.lessThan(isScreen02)`
        width: 375px;
        margin-left: 4%;
        padding: 40px 24px;
    `}
	${media.lessThan(isScreen02)`
        width: unset;
        margin-left: 0px;
        border-radius: 0px;
    `}
	${media.lessThan(isScreen02)`
		padding: 32px 24px 16px;
	`}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const PieChartView = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`;
const PieChartData = styled.div`
	display: flex;
	flex-direction: row;
	gap: 24px;
	padding-bottom: 48px;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	${media.lessThan("medium")`
        flex-direction: column;
		margin-top: 32px;
    `}
`;
const PresentContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: 12px;
	background-color: ${ColorV2.surface.brown};
	${media.lessThan("medium")`
        flex-direction: column-reverse;
		align-items: center;
    `}
`;
const PresentData = styled.div`
	padding: 24px;
	flex: 1;
	${media.lessThan("medium")`
		padding: 0px 24px 24px;
	`}
`;
const PresentImage = styled.img`
	width: 160px;
	object-fit: cover;
	object-position: left;
`;
const ChartImage = styled.img`
	position: absolute;
	top: 16px;
	right: calc(50% - 128 / 2px);
	height: 128px;
	width: 128px;
`;

const PriceLeft = (props: PriceLeftProps) => {
	const chartOptions = [
		{
			value: 85,
			color: ColorV2.text.primary,
		},
		{
			value: 15,
			color: ColorV2.surface.primaryLow,
		},
	];
	const auaMeans = [
		{
			title: "Las personas mayores no pagar nada",
			subtitle:
				"Gracias a tu donación, los mayores reciben acompañamiento semanal y participan en nuestras actividades de forma gratuita.",
			icon: <LetterIcon type="01" />,
		},
		{
			title: "Abuelos eternos",
			subtitle:
				"Ellos han dado todo por nosotros y ha llegado el momento de darlo todo por ellos. El siglo XXI no es el siglo de la inteligencia artificial, es el siglo de la inclusión social.",
			icon: <LetterIcon type="02" />,
		},
		{
			title: "Bienestar emocional",
			subtitle:
				"Estamos comprometidos con la salud mental, por eso los casos más graves de depresión reciben acompañamiento psicológico gratuito.",
			icon: <LetterIcon type="03" />,
		},
	];

	return (
		<Container animated={true}>
			<Text
				type="h3"
				weight="semibold"
				style={{
					color: ColorV2.text.primary,
					textAlign: "center",
					lineHeight: "40px",
				}}
			>
				Adopta Un Abuelo solo vive gracias a vuestras donaciones
			</Text>
			<Text type="p" style={{ textAlign: "center", marginTop: 16 }}>
				Somos una Fundación totalmente independiente que se sostiene
				gracias a nuestros socios.
			</Text>
			<Text
				type="h4"
				weight="semibold"
				style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}
			>
				¿Cómo se usa mi donación?
			</Text>
			<PieChartView>
				<PieChart
					lineWidth={12}
					style={{ height: 160, width: 160 }}
					data={chartOptions}
				/>
				<ChartImage
					src={require("../../../assets/images/letter/volunteer_grandpa.webp")}
				/>
			</PieChartView>
			<PieChartData>
				<Col style={{ textAlign: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{ color: ColorV2.text.primary }}
					>
						85%
					</Text>
					<Text type="h6" weight="medium" style={{ marginBottom: 8 }}>
						Programas con mayores
					</Text>
					<Text type="c1">
						Todas las actividades en las que participan, la compañía
						que reciben y la asistencia psicológica en caso de
						depresión, es gratuita para ellos.
					</Text>
				</Col>
				<Col style={{ textAlign: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{ color: ColorV2.surface.primaryLow }}
					>
						15%
					</Text>
					<Text type="h6" weight="medium" style={{ marginBottom: 8 }}>
						Costes operacionales
					</Text>
					<Text type="c1">
						Nos permiten funcionar como organización y mejorar
						nuestros servicios. Aquí va incluido el 5% de comisiones
						bancarias por las transacciones.
					</Text>
				</Col>
			</PieChartData>
			<Col
				style={{
					padding: "48px 0px",
					borderBottom: "1px solid " + ColorV2.border.neutralSoft,
				}}
			>
				<Text
					type="h4"
					weight="semibold"
					style={{ textAlign: "center", marginBottom: 48 }}
				>
					Gracias a tu ayuda logramos:
				</Text>
				<Col style={{ gap: 16 }}>
					{auaMeans.map((item, index) => (
						<Row key={"aua-" + index} style={{ gap: 12 }}>
							{item.icon}
							<Col style={{ flex: 1 }}>
								<Text
									type="h6"
									weight="medium"
									style={{ marginBottom: 4 }}
								>
									{item.title}
								</Text>
								<Text type="p2">{item.subtitle}</Text>
							</Col>
						</Row>
					))}
				</Col>
			</Col>
			<Col
				style={{
					padding: "48px 0px 0px",
				}}
			>
				<Text
					type="h4"
					weight="semibold"
					style={{ textAlign: "center", marginBottom: 48 }}
				>
					Tu donación tiene un premio muy especial
				</Text>
				<PresentContainer>
					<PresentData>
						<Text
							type="h5"
							weight="semibold"
							style={{ marginBottom: 8 }}
						>
							Consigue una taza exclusiva diseñada por Miki Noëlle
						</Text>
						<Text type="p2">
							Si donas 150€ o más, conseguirás de regalo una taza
							diseñada por Miki Noëlle en exclusiva para Adopta Un
							Abuelo.
						</Text>
						{/* <Button
							design="secondary"
							size="small"
							color={ColorV2.text.neutralHard}
							style={{
								width: "fit-content",
								height: 40,
								marginTop: 16,
								color: ColorV2.text.neutralHard,
							}}
						>
							Saber más
						</Button> */}
					</PresentData>
					<PresentImage
						src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
					/>
				</PresentContainer>
			</Col>
		</Container>
	);
};
export default PriceLeft;
export interface PriceLeftProps {}
