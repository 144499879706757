import { useState } from "react";

import { Modal, Input } from '@adoptaunabuelo/react-components';

const AmountModal = (props: Props) =>{

    const [ amount, setAmount ] = useState<number | undefined>(undefined);
    const [ error, setError ] = useState<string | undefined>(undefined);

    const onClick = () =>{
        if(amount){
            if(amount >= 5){
                if(amount <= 999999){
                    props.onClick(amount);
                    props.onClose();
                }
                else
                    setError('¡Wow! Agradecemos tu generosidad, pero por motivos técnicos la donación máxima es de 999.999'+props.currencySimbol)
            }
            else
                setError('Para asegurarnos de que un mayor recibe el acompañamiento necesario, la donación mínima es de 5'+props.currencySimbol+'. Disculpa las molestias.')
        }
        else{
            setError('Introduce una cantidad válida');
        }
    }

    const onInputChange = (e: any) =>{
        setError(undefined);
        const value = e.target.value;
        if(value.length === 0)
            setAmount(undefined)
        else{
            if((/^[0-9]+$/).test(value))
                setAmount(value);
        }
    }

    return(
        <Modal
            isVisible={props.isVisible}
            title={'Cantidad personalizada'}
            subtitle={'Escribe la cantidad con la que quieres colaborar con los mayores'}
            style={{width: 400}}
            buttonProps={{
                children: 'Confirmar',
                onClick: onClick
            }}
            onClose={props.onClose}
        >
            <Input
                type="text"
                placeholder="Tu cantidad"
                design="secondary"
                value={amount}
                containerStyle={{marginBottom: 24, marginTop: 2}}
                error={error}
                onChange={onInputChange}
            />
        </Modal>
    )
}
export default AmountModal;
export interface Props{
    isVisible: boolean,
    currencySimbol?: string,
    onClick: (amount: number) => void
    onClose: () => void
}