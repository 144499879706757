import { useRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme, UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import { Modal, Text, Button, ColorV2 } from "@adoptaunabuelo/react-components";
import { Download, X } from "lucide-react";
import LetterCanvas, {
	CanvasRef,
} from "../../../components/canvas/LetterCanvas";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	padding: 32px 8px 40px;
	gap: 32px;
	${media.lessThan("medium")`
        flex-direction: column;
        align-items: center;
    `}
	${media.lessThan("small")`
        padding: 32px 0px 24px;
    `}
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
`;
const Number = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	background-color: white;
	border-radius: 30px;
`;
const Image = styled.div`
	width: 250px;
	aspect-ratio: 46/81;
	background-color: white;
	border-radius: 12px;
	overflow: hidden;
	${media.lessThan("small")`
        width: 180px;
    `}
`;
const ButtonStyled = styled(Button)<{ $backgroundColor?: string }>`
	background-color: ${(props) =>
		props.$backgroundColor
			? props.$backgroundColor
			: ColorV2.surface.primaryHard};
	width: fit-content;
	margin-top: 32px;
	${media.lessThan("medium")`
        width: unset;
    `}
`;

const InstagramModal = (props: Props) => {
	const canvasRef = useRef<CanvasRef>(null);
	const isMobile = useSelector((state: State) => state.screen.isMobile);

	const downloadImage = () => {
		const base64 = canvasRef.current?.getBase64();
		if (base64) {
			const file = new File([base64], "mi_carta.png");
			if (navigator.canShare && navigator.canShare({ files: [file] })) {
				navigator.share({
					files: [file],
				});
			} else {
				const downloadLink = document.createElement("a");
				const fileName = "mi_carta.png";
				downloadLink.href = base64;
				downloadLink.download = fileName;
				downloadLink.click();
			}
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={props.onClose}
			type={isMobile ? "full-screen" : "default"}
			style={{
				backgroundColor:
					props.coorporate && props.coorporate.color
						? props.coorporate.color.main
						: ColorV2.surface.primary,
				width: 800,
			}}
			hideHeader={true}
		>
			<Button
				style={{ position: "absolute", top: 8, right: 8 }}
				design={"image"}
				icon={<X color={"white"} />}
				onClick={props.onClose}
			/>
			<Row>
				<Column>
					<Image>
						{props.image && (
							<LetterCanvas
								ref={canvasRef}
								style={{ width: "100%", height: "100%" }}
								image={props.image}
								name={props.grandpa.name}
								birthday={props.grandpa.birthday?.iso}
							/>
						)}
					</Image>
				</Column>
				<Column style={{ justifyContent: "center" }}>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginBottom: 32, color: "white" }}
					>
						Cómo compartir la imagen en Instagram
					</Text>
					<Column style={{ gap: 18 }}>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">1</Number>
							<Text type="p" style={{ color: "white", flex: 1 }}>
								Guarda la imagen en tu galería.
							</Text>
						</Cell>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">2</Number>
							<Text type="p" style={{ color: "white", flex: 1 }}>
								Crea un storie en Instagram y usa la imagen que
								acabas de descargar.
							</Text>
						</Cell>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">3</Number>
							<Text type="p" style={{ color: "white", flex: 1 }}>
								¡Y a compartir! Te agradecemos mucho que animes
								a otros a que escriban su carta. ¡Eres
								increíble!
							</Text>
						</Cell>
					</Column>
					<ButtonStyled
						icon={<Download />}
						$backgroundColor={
							props.coorporate &&
							props.coorporate.color &&
							props.coorporate.color.secondary
						}
						onClick={downloadImage}
					>
						Guardar imagen
					</ButtonStyled>
				</Column>
			</Row>
		</Modal>
	);
};
export default InstagramModal;
export interface Props {
	isVisible: boolean;
	image?: HTMLImageElement;
	grandpa: UserScheme;
	coorporate?: CoorporateScheme;
	onClose: () => void;
}
