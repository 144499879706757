import {
	CoorporateScheme,
	ErrorScheme,
	Letter,
	LetterScheme,
	UserScheme,
} from "client-v2";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Button, ColorV2, Text } from "@adoptaunabuelo/react-components";
import EditLetterComponent, {
	EditLetterProps,
} from "../../../components/section/EditLetter";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import SectionContainer from "./SectionContainer";

const screen01: any = "1350px";
const screen02: any = "1050px";
const LetterContainer = styled.div`
	display: flex;
	width: 556px;
	aspect-ratio: 1/1.414;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
	${media.lessThan(screen01)`
		width: 456px;
		margin-top: 40px;
    `}
	${media.lessThan(screen02)`
		width: 356px;
    `}
	${media.lessThan("medium")`
		aspect-ratio: unset;
        margin: 0px;
		width: 100%;
    `}
`;
const EditContainer = styled.div`
	display: none;
	flex-direction: column;
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: ${ColorV2.surface.background};
	padding: 0px 24px 36px;
	${media.lessThan("medium")`
        display: flex;
		padding: 0px 24px 16px;
    `}
`;

const PDFLetter = (props: PDFLetterProps) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
			  };
	const appLocation = useSelector((state: State) => state.location.location);
	const containerRef = useRef<HTMLDivElement>(null);
	const [html, setHtml] = useState("");
	const [scale, setScale] = useState(1);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [letter, setLetter] = useState<LetterScheme | undefined>(undefined);

	useEffect(() => {
		loadHTML(scale);
	}, [props.font, props.personalInfo, props.personalInfo, props.stamp]);

	useEffect(() => {
		const updateScale = () => {
			if (containerRef.current) {
				const containerWidth = containerRef.current.offsetWidth; // Ancho del contenedor
				const contentWidth = 793.7; // Ancho del contenido del iframe en píxeles (210mm convertido a píxeles)
				const newScale = containerWidth / contentWidth;
				setScale(newScale);
				loadHTML(newScale);
			}
		};
		updateScale();
		window.addEventListener("resize", updateScale);
		return () => {
			window.removeEventListener("resize", updateScale);
		};
	}, []);

	const loadHTML = async (scale: number) => {
		const response = await fetch(`${process.env.PUBLIC_URL}/assets/letter/letter.html`);
		let html = await response.text();
		html = html.replace("{{content}}", props.content);
		html = html.replace(
			"{{name}}",
			`${props.user.name} ${props.user.surname}`
		);
		html = html.replace(
			"{{years}}",
			`${moment().diff(moment(props.user.birthday?.iso), "year")} años`
		);
		html = html.replace(
			`font-family: "Poppins";`,
			`font-family: "${props.font}";`
		);

		html = html.replace(
			/transform: scale\(([^)]+)\);/,
			`transform: scale(${scale});`
		);

		if (props.corporate) {
			html = html.replace("{{stamp_img}}", "./assets/letter/stamp_empty.png");
			html = html.replace(
				"{{corporate_logo}}",
				`<img src="${props.corporate.logo?.url}"/>`
			);
		} else {
			html = html.replace("{{stamp_img}}", "./assets/letter/stamp_retro.png");
			html = html.replace("{{corporate_logo}}", "");
		}
		if (props.stamp) {
			html = html.replace("stamp-aua hide", "stamp-aua show");
		} else {
			html = html.replace("stamp-aua show", "stamp-aua hide");
		}
		if (props.personalInfo) {
			html = html.replace("header hide", "header show");
		} else {
			html = html.replace("header show", "header hide");
		}
		if (props.image) {
			html = html.replace(
				"{{user-image}}",
				`<div class="aua__page-break"></div> <div class="letter-image"><img src="${props.image}" /></div>`
			);
		} else {
			html = html.replace("{{user-image}}", "");
		}
		setHtml(html);
	};

	const onNextClick = () => {
		setLoading(true);
		Letter.create({
			userId: props.user.objectId,
			corporateId: props.corporate?.objectId,
			grandpaId: props.grandpa.objectId,
			content: props.content,
			country: appLocation?.country_code,
			language: appLocation?.language,
			image: props.image,
			customization: {
				stamp: props.stamp,
				font: props.font,
				personalInfo: props.personalInfo,
			},
		})
			.then((result) => {
				setLetter(result.data);
				localStorage.removeItem("letter");
				setLoading(false);
				setSuccess(true);
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
				props.onFinish({ error: e.message });
			});
	};

	return (
		<SectionContainer style={{ padding: 0 }} animated={props.animated}>
			<LetterContainer ref={containerRef}>
				<iframe
					title="Letter Preview"
					style={{
						border: "none",
						width: "100%",
					}}
					srcDoc={html}
				/>
			</LetterContainer>
			<EditContainer>
				<Text
					type="p"
					style={{ margin: "22px 0px", textAlign: "center" }}
					weight="medium"
				>
					Opciones de personalización
				</Text>
				<EditLetterComponent style={{ margin: "0px" }} {...props} />
				<Button
					style={{
						marginTop: 24,
						width: "100%",
						backgroundColor: success
							? ColorV2.surface.green
							: colors.buttonBackground,
						color: success ? "white" : colors.buttonColor,
					}}
					loading={loading}
					success={success}
					onSuccess={() => {
						props.onFinish({ data: letter });
					}}
					onClick={onNextClick}
				>
					Enviar mi carta
				</Button>
			</EditContainer>
		</SectionContainer>
	);
};
export default PDFLetter;
export interface PDFLetterProps extends EditLetterProps {
	user: UserScheme;
	grandpa: UserScheme;
	corporate?: CoorporateScheme;
	content: string;
	image?: string;
	font: string;
	personalInfo: boolean;
	stamp: boolean;
	animated?: boolean;
	onFinish: (result: { data?: LetterScheme; error?: string }) => void;
}
