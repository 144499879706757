import { useState, useCallback, useEffect, CSSProperties } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '@adoptaunabuelo/react-components';

const ReCaptchaButton = (props: Props) =>{

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { onError, ...restProps } = props;

    const [ loading, setLoading ] = useState(props.loading);

    useEffect(() =>{
        setLoading(props.loading);
    },[props.loading]);

    const onClick = useCallback(async (e: any) => {
        if (!executeRecaptcha) {
            props.onError && props.onError('Parece que reCaptcha aun no está cargado.');
            return;
        }
        setLoading(true);
        executeRecaptcha(props.action).then((token: string) => {
            props.onClick && props.onClick(e);
            setLoading(false);
            return;
        }).catch((error: Error) =>{
            props.onError && props.onError(error.message);
            setLoading(false);
            return;
        });
    }, [executeRecaptcha]);

    return(
        <Button
            {...restProps}
            loading={loading}
            onClick={onClick}
        />
    )
}
export default ReCaptchaButton;
export interface Props{
    style?: CSSProperties,
    loading?: boolean,
    children: string,
    action: string,
    onClick?: (e: any) => void,
    onError?: (error: string) => void
}