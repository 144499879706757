import { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme, LetterScheme, UserScheme } from "client-v2";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import HeartAnimation from "../../../assets/animations/heart.json";
import { Button, Color, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { Download, Instagram } from "lucide-react";
import InstagramModal from "./InstagramModal";
import Relief from "../../../components/section/Relief";
import SectionContainer from "./SectionContainer";

const screen01: any = "1350px";
const screen02: any = "1050px";
const SectionContainerStyled = styled(SectionContainer)`
	padding-right: 92px;
	width: 388px;
	overflow-y: scroll;
	padding-bottom: 48px;
	${media.lessThan(screen01)`
		padding-right: 64px;
    `}
	${media.lessThan(screen02)`
        padding-right: 32px;
    `}
	${media.lessThan("medium")`
		overflow-y: unset;
        width: unset;
		padding-right: 24px;
		padding-bottom: 24px;
    `}
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 13px !important;
	height: 24px;
	width: 24px;
	border-radius: 30px;
	border: 2px solid;
	color: ${(props) =>
		props.color ? props.color : ColorV2.text.neutralHard} !important;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [loadingIG, setLoadingIG] = useState(false);
	const [successPDF, setSuccessPDF] = useState(false);
	const [instagramImage, setInstagramImage] = useState<
		HTMLImageElement | undefined
	>(undefined);
	const [heartAnimation, setHeartAnimation] = useState<JSON | undefined>(
		undefined
	);

	useEffect(() => {
		createAnimation();
		setLoadingPDF(false);
		setLoadingIG(false);
		setSuccessPDF(false);
	}, []);

	const createAnimation = () => {
		const json = JSON.stringify(HeartAnimation);
		if (props.corporate && props.corporate.color) {
			//Get rgb colors
			const color = props.corporate.color.main;
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
				color
			);
			if (result) {
				const rgb = {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
				};
				let temp = json.replaceAll(
					"[0,0.5608,0.9608]",
					`[${rgb.r / 255},${rgb.g / 255},${rgb.b / 255}]`
				);
				temp = temp.replaceAll(
					"[0,0.3529,0.6588]",
					`[${rgb.r / 255},${rgb.g / 255},${rgb.b / 255}]`
				);
				temp = temp.replaceAll(
					"[0,0.1922,0.3608]",
					`[${rgb.r / 255},${rgb.g / 255},${rgb.b / 255}]`
				);
				setHeartAnimation(JSON.parse(temp));
			} else {
				setHeartAnimation(JSON.parse(json));
			}
		} else {
			setHeartAnimation(JSON.parse(json));
		}
	};

	const downloadLetter = async () => {
		if (props.letter) {
			setLoadingPDF(true);
			const response = await fetch(
				`${process.env.PUBLIC_URL}/assets/letter/letter.html`
			);
			let html = await response.text();
			html = html.replace("{{content}}", props.letter.content);
			html = html.replace(
				"{{name}}",
				`${props.user.name} ${props.user.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(props.user.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					props.letter.customization
						? props.letter.customization.font
						: "Poppins"
				}";`
			);
			if (props.corporate) {
				html = html.replace(
					"{{stamp_img}}",
					"./assets/letter/stamp_empty.png"
				);
				html = html.replace(
					"{{corporate_logo}}",
					`<img src="${props.corporate.logo?.url}"/>`
				);
			} else {
				html = html.replace(
					"{{stamp_img}}",
					"./assets/letter/stamp_retro.png"
				);
				html = html.replace("{{corporate_logo}}", "");
			}
			if (props.letter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (props.letter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (props.letter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${props.letter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			//Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoadingPDF(false);
				});
		}
	};

	const downloadImage = async () => {
		var img = new Image();
		img.src = require("../../../assets/images/storie/letter_01.png");
		img.onload = () => {
			setInstagramImage(img);
		};
	};

	return (
		<SectionContainerStyled animated={props.animated}>
			{props.grandpa && (
				<InstagramModal
					isVisible={instagramImage ? true : false}
					grandpa={props.grandpa}
					coorporate={props.corporate}
					image={instagramImage}
					onClose={() => setInstagramImage(undefined)}
				/>
			)}
			{heartAnimation && (
				<Player
					style={{
						width: isMobile ? 300 : 400,
						height: isMobile ? 300 : 400,
						marginTop: isMobile ? -100 : -140,
						marginBottom: isMobile ? -100 : -140,
					}}
					keepLastFrame={true}
					autoplay={true}
					src={heartAnimation}
				/>
			)}
			<Text type="h3" weight="semibold" style={{ textAlign: "center" }}>
				¡Muchas gracias, {props.user.name}!
			</Text>
			<Text type="p" style={{ marginTop: 12, textAlign: "center" }}>
				Hemos recibido tu carta. Gracias a gente como tú hacemos un
				poquito más felices a nuestros mayores.
			</Text>
			{props.letter && (
				<Col style={{ alignItems: "center", gap: 8, marginTop: 48 }}>
					<Button
						design={"secondary"}
						size="small"
						icon={<Download color={ColorV2.text.neutralHard} />}
						style={{
							width: "100%",
							color: ColorV2.text.neutralHard,
						}}
						color={ColorV2.text.neutralHard}
						loading={loadingPDF}
						success={successPDF}
						onClick={downloadLetter}
					>
						Descarga mi carta
					</Button>
					<Button
						design={"secondary"}
						size="small"
						icon={<Instagram color={ColorV2.text.neutralHard} />}
						style={{
							width: "100%",
							color: ColorV2.text.neutralHard,
						}}
						color={ColorV2.text.neutralHard}
						loading={loadingIG}
						onClick={downloadImage}
					>
						Compartir en Stories
					</Button>
				</Col>
			)}
			{appLocation &&
				appLocation.country_code === "ES" &&
				props.didSubscribe &&
				props.user &&
				!props.user.idCard &&
				!props.user.zipCode && (
					<Relief style={{ marginTop: 32 }} user={props.user} />
				)}
			<Section>
				<Text
					type="h4"
					weight="semibold"
					style={{ marginBottom: 24, textAlign: "center" }}
				>
					Que viene después
				</Text>
				<HowWork>
					<Row>
						<Numbers type="p" weight="semibold">
							1
						</Numbers>
						<Col>
							<Text type="p" weight="medium">
								Revisamos todas las cartas
							</Text>
							<Text
								type="p2"
								style={{
									color: Color.text.high,
									marginTop: 4,
								}}
							>
								Recopilamos las cartas y las leemos una a una
								para asegurarnos que las cartas son adecuadas.
							</Text>
						</Col>
					</Row>
					<Row>
						<Numbers type="p" weight="semibold">
							2
						</Numbers>
						<Col>
							<Text type="p" weight="medium">
								Las enviamos a las residencias
							</Text>
							<Text
								type="p2"
								style={{
									color: Color.text.high,
									marginTop: 4,
								}}
							>
								Una vez organizadas las cartas, se envían
								directas a las residencias para que las impriman
								y se la entreguen en mano a los abuelos.
							</Text>
						</Col>
					</Row>
					<Row>
						<Numbers type="p" weight="semibold">
							3
						</Numbers>
						<Col>
							<Text type="p" weight="medium">
								¡Día de lectura!
							</Text>
							<Text
								type="p2"
								style={{
									color: Color.text.high,
									marginTop: 4,
								}}
							>
								En algunas residencias se escoge un día para que
								los abuelos que quieran lean sus cartas delante
								del resto.
							</Text>
						</Col>
					</Row>
				</HowWork>
			</Section>
			{props.children}
		</SectionContainerStyled>
	);
};
export default Success;
export interface Props {
	children?: React.ReactNode;
	animated?: boolean;
	letter?: LetterScheme;
	corporate?: CoorporateScheme;
	grandpa?: UserScheme;
	user: UserScheme;
	didSubscribe?: boolean;
	onNextClick?: () => void;
}
