import { Button, ColorV2, Modal, Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import styled from "styled-components";
import media from "styled-media-query";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan("medium")`
        flex-direction: column;
	`}
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const ImageView = styled.div`
	display: flex;
	flex-direction: column;
	${media.lessThan("medium")`
        align-items: center;
	`}
`;
const Image = styled.img`
	height: 240px;
	width: 240px;
	border-radius: 12px;
`;

const PresentModal = (props: PresentModalProps) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	return (
		<Modal
			{...props}
			style={{ width: 768 }}
			type={isMobile ? "full-screen" : "default"}
		>
			<Row style={{ gap: 32 }}>
				<ImageView>
					<Image
						src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
					/>
				</ImageView>
				<Col>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginBottom: 16 }}
					>
						Consigue una taza diseñada por Miki Noëlle
					</Text>
					<Text type="p" style={{ marginBottom: 16 }}>
						Si donas 150€ o más, nos aseguramos de que una persona
						mayor esté acompañada durante todo el año.
					</Text>
					<Text
						type="p"
						style={{ marginBottom: 16 }}
						weight={"semibold"}
					>
						Somos Fundación así que, si resides en España, te
						devolverán en 2025 hasta el 80% de lo que hayas donado
						en 2025.
					</Text>
					<Text type="p" style={{ marginBottom: 32 }}>
						Siéntete orgulloso y celebra con tu nueva taza que aún
						podemos disfrutar de nuestros mayores, que tanto han
						hecho por nosotros.
					</Text>
					<Button
						style={{
							marginBottom: 16,
							backgroundColor: ColorV2.surface.primaryHard,
						}}
						onClick={props.onClick}
					>
						Quiero mi taza
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
export default PresentModal;
export interface PresentModalProps {
	isVisible: boolean;
	onClick: () => void;
	onClose: () => void;
}
