import { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import { ChallengeScheme } from "client-v2";
import media from 'styled-media-query';

import { Text, ColorV2 } from "@adoptaunabuelo/react-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 12px;
    height: 442px;
    width: 100%;
    background-color: ${ColorV2.surface.primaryHard};
    ${media.lessThan("small")`
        flex-direction: column;
        height: unset;
    `}
`
const ImageView = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    min-height: 442px;
    ${media.lessThan("small")`
        min-height: unset;
        max-height: 300px;
        height: 300px;
    `}
`
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const Gradient = styled.div<{color?: string}>`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: linear-gradient(270deg, ${props => props.color ? props.color : ColorV2.surface.primaryHard} 0%, rgba(0, 49, 92, 0.00) 50%);
    ${(props) => media.lessThan("small")`
        background: linear-gradient(0deg, ${props.color ? props.color : ColorV2.surface.primaryHard} 0%, rgba(0, 49, 92, 0.00) 50%);
    `}
`
const DataContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 48px 48px 48px 16px;
    justify-content: center;
    ${media.lessThan("small")`
        padding: 16px 16px 24px;
    `}
`

const Cell = (props: Props) =>{

    const { challenge, backgroundColor, titleColor, ...restProps } = props;

    return(
        <Container
            {...restProps}
            style={{backgroundColor: backgroundColor, ...restProps.style}}
        >
            <ImageView>
                <Image
                    src={challenge.image.url}
                />
                <Gradient
                    color={backgroundColor}
                />
            </ImageView>
            <DataContainer>
                <Text type='h3' weight="semibold" style={{color: titleColor ? titleColor : 'white'}}>
                    {challenge.title}
                </Text>
                {props.children}
            </DataContainer>
        </Container>
    )
}
export default Cell;
export interface Props extends ComponentPropsWithRef<"div">{
    challenge: ChallengeScheme,
    backgroundColor?: string,
    titleColor?: string
}