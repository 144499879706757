import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { ChallengeScheme, UserScheme } from "client-v2";

import ReliefModal from "../modal/ReliefModal";
import ResumeStyled from "./Resume";
import {
	Text,
	Color,
	Button,
	ProgressBar,
	ColorV2,
	Menu,
	Counter,
} from "@adoptaunabuelo/react-components";
import {
	Calendar,
	Facebook,
	Flower,
	Link,
	MessageCircle,
	PartyPopper,
	Share,
	Twitter,
	Users2,
} from "lucide-react";

const Section = styled.div``;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const CellContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const Image = styled.img`
	height: 40px;
	width: 40px;
	min-height: 40px;
	min-width: 40px;
	border-radius: 40px;
	margin-right: 16px;
	object-fit: cover;
`;
const SpanLink = styled.span`
	cursor: pointer;
	text-decoration: underline;
`;

const Resume = (props: Props) => {
	const sashidoUrl =
		process.env.NODE_ENV === "production"
			? "https://sqe78ajvu1g4l9vzisq7gst60ulsui.files-sashido.cloud"
			: "https://zmadwoa9sdn103iq5yeq37v173sz60.files-sashido.cloud";
	const [showReliefModal, setShowReliefModal] = useState(false);
	const [pastDate, setPastDate] = useState(false);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		//Check if past date
		const diff = moment(props.challenge.endDate.iso).diff(moment(), "days");
		if (diff <= 0) setPastDate(true);

		//Check if goal completed
		if (props.challenge.type === "birthday") {
			setCompleted(false);
		} else {
			const restAmount = props.challenge.goal - props.challenge.amount;
			if (restAmount <= 0) setCompleted(true);
		}
	}, [props.challenge]);

	return (
		<ResumeStyled
			style={{
				flexDirection: "column",
			}}
		>
			<ReliefModal
				isVisible={showReliefModal}
				price={50}
				charge={"one"}
				onClose={() => setShowReliefModal(false)}
			/>
			<Section style={{ paddingBottom: 16 }}>
				{props.challenge.type === "birthday" ? (
					<Row
						style={{
							alignItems: "center",
							gap: 12,
							marginBottom: 24,
						}}
					>
						<Counter
							amount={
								18 + (props.letterCount ? props.letterCount : 0)
							}
							height={52}
							width={36}
							fontSize={44}
						/>
						<Text
							type="h3"
							weight="semibold"
							style={{ color: Color.text.primary }}
						>
							felicitaciones
						</Text>
					</Row>
				) : (
					<>
						<ProgressBar
							style={{ height: 8, marginBottom: 8 }}
							progress={[
								{
									value:
										(props.challenge.amount /
											props.challenge.goal) *
										100,
									color: completed
										? Color.status.color.success
										: props.challenge.type === "rose"
										? ColorV2.surface.redMedium
										: Color.background.primary,
								},
							]}
							animationTime={0.3}
							animationDelay={0.5}
						/>

						<Row style={{ alignItems: "center", marginBottom: 24 }}>
							<Text
								type="h5"
								weight={"medium"}
								style={{
									color: completed
										? Color.status.color.success
										: props.challenge.type === "rose"
										? ColorV2.surface.redMedium
										: Color.text.primary,
								}}
							>
								{props.challenge.amount.toLocaleString()}
								{props.challenge.type === "signature"
									? ""
									: "€"}
							</Text>
							<Text
								type="p"
								style={{
									marginLeft: 6,
									color: Color.text.high,
								}}
							>
								de {props.challenge.goal.toLocaleString()}
								{props.challenge.type === "signature"
									? " firmas"
									: "€"}
							</Text>
						</Row>
					</>
				)}
				<Col style={{ gap: 8 }}>
					{props.challenge.type !== "birthday" && completed && (
						<Row>
							<PartyPopper
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.green}
							/>
							<Text
								type="p"
								style={{
									marginTop: 1,
								}}
							>
								<span style={{ color: ColorV2.text.green }}>
									¡Conseguido!
								</span>{" "}
								{props.challenge.type === "dream"
									? "Puedes seguir colaborando y ayudarnos a cumplir los sueños de otros abuelos."
									: props.challenge.type === "signature"
									? "Hemos llegado al objetivo, pero puedes seguir firmando y ayudando."
									: ""}
							</Text>
						</Row>
					)}
					{props.challenge.type === "rose" ? (
						<Row>
							<Users2
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text
								type="p"
								style={{
									color: ColorV2.text.neutralMedium,
									marginTop: 1,
								}}
							>
								<span
									style={{
										fontWeight: 600,
										color: ColorV2.text.neutralHard,
									}}
								>
									{props.challenge.supporters.toLocaleString() +
										" personas "}
								</span>
								han colaborado enviando una rosa
							</Text>
						</Row>
					) : (
						props.challenge.type !== "signature" &&
						props.challenge.type !== "birthday" && (
							<Row>
								<Users2
									style={{
										marginRight: 12,
										minHeight: 24,
										minWidth: 24,
									}}
									height={24}
									width={24}
									color={ColorV2.text.neutralHard}
								/>
								<Text
									type="p"
									style={{
										marginTop: 1,
									}}
								>
									{props.challenge.supporters.toLocaleString() +
										" personas han colaborado"}
								</Text>
							</Row>
						)
					)}
					{props.challenge.type === "rose" ? (
						<Row style={{ marginTop: 16 }}>
							<Flower
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text type="p" style={{ marginTop: 1 }}>
								Enviar una rosa tiene un coste de 5€
							</Text>
						</Row>
					) : (
						!completed &&
						!pastDate && (
							<Row>
								<Calendar
									style={{
										marginRight: 12,
										minHeight: 24,
										minWidth: 24,
									}}
									height={24}
									width={24}
									color={ColorV2.text.neutralHard}
								/>
								<Text
									type="p"
									style={{
										marginTop: 1,
									}}
								>
									{"Quedan "}
									{moment(props.challenge.endDate.iso).diff(
										moment(),
										"days"
									)}
									{" días"}
								</Text>
							</Row>
						)
					)}
				</Col>
				<Row style={{ marginTop: 32, gap: 8 }}>
					<Button
						style={{
							flex: 1,
							backgroundColor:
								props.challenge.type === "rose"
									? ColorV2.surface.redMedium
									: ColorV2.surface.primary,
						}}
						onClick={props.onClick}
					>
						{props.challenge.type === "signature"
							? "Quiero firmar"
							: props.challenge.type === "rose"
							? "Quiero regalar una rosa"
							: props.challenge.type === "birthday"
							? "Enviar mi felicitación"
							: "Quiero colaborar"}
					</Button>
					<Menu
						id={"share"}
						position={"bottom-left"}
						menuStyle={{ width: 200, top: 60 }}
						options={[
							{
								id: "twitter",
								label: "Twitter",
								icon: <Twitter height={20} width={20} />,
							},
							{
								id: "facebook",
								label: "Facebook",
								icon: <Facebook height={20} width={20} />,
							},
							{
								id: "whatsapp",
								label: "Whatsapp",
								icon: <MessageCircle height={20} width={20} />,
							},
							{
								id: "url",
								label: "Copiar enlace",
								icon: <Link height={20} width={20} />,
							},
						]}
						Icon={
							<Button
								design={"image"}
								icon={<Share />}
								style={{
									border:
										"1px solid " +
										ColorV2.border.neutralSoft,
									borderRadius: 40,
									height: 54,
									width: 54,
								}}
							/>
						}
						onClick={props.onShareClick}
					/>
				</Row>
				{props.challenge.type === "dream" && (
					<Row
						style={{
							backgroundColor: ColorV2.surface.background,
							padding: 16,
							borderRadius: 12,
							marginTop: 8,
						}}
					>
						<Text
							type="p2"
							style={{ color: ColorV2.text.neutralHard }}
						>
							Recupera hasta el{" "}
							<span
								style={{
									backgroundColor: ColorV2.surface.secondary,
									padding: "0px 6px",
									borderRadius: 3,
									fontWeight: 500,
								}}
							>
								80%
							</span>{" "}
							de tus donaciones en la Declaración de la Renta.{" "}
							<SpanLink onClick={() => setShowReliefModal(true)}>
								Saber más
							</SpanLink>
						</Text>
					</Row>
				)}
			</Section>
			<Section
				style={{
					paddingTop: 16,
				}}
			>
				<Text type="h5" weight="semibold" style={{ marginBottom: 16 }}>
					{props.challenge.type === "signature"
						? "Últimas personas que han firmado"
						: props.challenge.type === "birthday"
						? "Últimas felicitaciones"
						: "Últimos colaboradores"}
				</Text>
				<CellContainer>
					{props.supporters.map((item, index) => (
						<Cell key={"supporter-" + index}>
							<Image
								src={
									item.user.image
										? `${sashidoUrl}/${item.user.image.url
												.split("/")
												.pop()}`
										: require("../../assets/images/icon/user_icon.png")
								}
							/>
							<Text type="p2">
								<span style={{ fontWeight: 600 }}>
									{item.user.name}
								</span>{" "}
								{props.challenge.type === "signature"
									? " firmó "
									: props.challenge.type === "birthday"
									? " envió su felicitación "
									: " colaboró "}
								{moment(item.createdAt).fromNow()}
							</Text>
						</Cell>
					))}
				</CellContainer>
			</Section>
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	challenge: ChallengeScheme;
	supporters: { user: UserScheme; createdAt: Date }[];
	letterCount?: number;
	onClick?: () => void;
	onShareClick: (op: { id: string }) => void;
}
