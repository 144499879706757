import styled from "styled-components";
import SectionContainer from "./SectionContainer";
import { useEffect, useState } from "react";
import { ChallengeScheme, ErrorScheme, Feed } from "client-v2";
import ChallengeCell from "../../../components/cell/ChallengeCell";
import { Button, Text } from "@adoptaunabuelo/react-components";
import { ChevronLeft, ChevronRight } from "lucide-react";
import media from "styled-media-query";
import ChallengeLoadingAnimation from "../../../assets/animations/challenge_loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const screen01: any = "1350px";
const screen02: any = "1050px";
const SectionContainerStyled = styled(SectionContainer)`
	padding-left: 92px;
	width: 388px;
	justify-content: center;
	${media.lessThan(screen01)`
		padding-left: 64px;
    `}
	${media.lessThan(screen02)`
        padding-left: 32px;
    `}
	${media.lessThan("medium")`
        width: unset;
        padding-left: 0px;
		padding-right: 0px;
		padding-top: 42px;
    `}
`;
const ChallengeContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin-top: 32px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	padding: 9px 0px;
	margin-bottom: 8px;
	justify-content: space-between;
	align-items: center;
`;
const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const SuccessRight = (props: SuccessRightProps) => {
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const [loading, setLoading] = useState(false);
	const [challenges, setChallenges] = useState<
		{ obj: ChallengeScheme; amount?: number }[]
	>([]);
	const [index, setIndex] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);

	useEffect(() => {
		getChallenges();
	}, []);

	const getChallenges = async () => {
		//TODO revisar este método
		setLoading(true);
		Feed.getForWeb({
			version: 1,
		})
			.then((result) => {
				setChallenges(result.data);
				setTotalAmount(
					result.pagination?.count ? result.pagination?.count : 0
				);
				setLoading(false);
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<SectionContainerStyled
			title="Otras iniciativas de Adopta Un Abuelo"
			subtitle="Queremos cubrir las necesidades de los mayores para hacer su vida más fácil. Tu aportación, por pequeña que sea, tiene un gran impacto."
			animated={props.animated}
		>
			<ChallengeContainer>
				<Row>
					<Text type="p">
						{index + 1}/{totalAmount} iniciativas
					</Text>
					<ButtonContainer>
						<Button
							style={{
								backgroundColor: "white",
								borderRadius: 100,
							}}
							design="image"
							icon={<ChevronLeft />}
							disabled={index <= 0 || loading}
							onClick={() => {
								if (index > 0) setIndex(index - 1);
							}}
						/>
						<Button
							style={{
								backgroundColor: "white",
								borderRadius: 100,
							}}
							design="image"
							icon={<ChevronRight />}
							disabled={index >= totalAmount - 1 || loading}
							onClick={() => {
								if (index < totalAmount - 1)
									setIndex(index + 1);
							}}
						/>
					</ButtonContainer>
				</Row>
				{loading || challenges.length <= 0 ? (
					<Player
						style={{
							width: isMobile ? "100%" : 388,
							height: 315,
						}}
						loop={true}
						autoplay={true}
						src={ChallengeLoadingAnimation}
					/>
				) : (
					<ChallengeCell
						challenge={challenges[index].obj}
						amount={challenges[index].amount}
					/>
				)}
			</ChallengeContainer>
		</SectionContainerStyled>
	);
};
export default SuccessRight;
export interface SuccessRightProps {
	animated?: boolean;
}
