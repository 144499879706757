const initialState = {
	translation: undefined,
	location: undefined,
};

export default function location(state = initialState, action: any) {
	switch (action.type) {
		case "APP_TRANSLATION": {
			return {
				...state,
				translation: action.data,
			};
		}
		case "APP_LOCATION": {
			return {
				...state,
				location: action.data as {
					country_code: string;
					currency: {
						name: string;
						symbol: string;
						hasSEPA: boolean;
					};
					language: string;
				},
			};
		}
		default:
			return state;
	}
}
