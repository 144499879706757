import styled from "styled-components";
import { ChallengeScheme } from "client-v2";
import moment from "moment";

import Cell from "./Cell";
import { Text, ProgressBar, ColorV2, Button } from "@adoptaunabuelo/react-components";
import { Users, Calendar } from "lucide-react";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: white;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const DreamCell = (props: Props) =>{
    return(
        <Cell
            challenge={props.challenge}
        >
            <Column style={{marginTop: 24}}>
                <ProgressBar
                    style={{backgroundColor: ColorV2.border.invertSoft, height: 8}}
                    progress={props.challenge.amount}
                    maxValue={props.challenge.goal}
                    color="white"
                />
                <Text type='h6' style={{color: 'white', marginTop: 8}}>
                    {props.challenge.amount.toLocaleString()} € <span style={{fontSize: 15, color: ColorV2.text.invertHigh}}>de {props.challenge.goal.toLocaleString()} €</span>
                </Text>
            </Column>
            <Row style={{marginTop: 24}}>
                <Users height={20} width={20}/>
                <Text type="p" style={{color: 'white'}}>
                    {props.challenge.supporters} personas han colaborado
                </Text>
            </Row>
            <Row style={{marginTop: 8}}>
                <Calendar height={20} width={20}/>
                <Text type="p" style={{color: 'white'}}>
                    Quedan {moment(props.challenge.endDate.iso).diff(moment(), 'day')} días para cerrar la campaña
                </Text>
            </Row>
            <Button 
                style={{width: 'fit-content', backgroundColor: 'white', marginTop: 40, color: ColorV2.text.neutralHard}}
                onClick={() => window.open('https://adoptaunabuelo.org/reto/?id='+props.challenge.objectId, "_blank")}
            >
                ¡Quiero colaborar!
            </Button>
        </Cell>
    )
}
export default DreamCell;
export interface Props{
    challenge: ChallengeScheme
}