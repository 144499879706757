import esTranslation from "./es.json";
import enTranslation from "./en.json";
import itTranslation from "./it.json";
import ptTranslation from "./pt.json";
import frTranslation from "./fr.json";
import deTranslation from "./de.json";

const translations = {
  es: esTranslation,
  en: enTranslation,
  it: itTranslation,
  pt: ptTranslation,
  fr: frTranslation,
  de: deTranslation
};

export default translations;