import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import {
	Volunteer,
	Track,
	UserScheme,
	CoorporateScheme,
	Constant,
	LetterScheme,
	ProductScheme,
} from "client-v2";
import SkipModal from "../../components/modal/SkipPaymentModal";
import WizardContainer, { WizardContainerRef } from "./section/WizardContainer";
import Login from "./section/Login";
import Code from "./section/Code";
import SectionContainer from "./section/SectionContainer";
import Personal from "./section/Personal";
import Search from "./section/Search";
import Stamp from "./section/Stamp";
import Letter from "./section/Letter";
import LetterLeft from "./section/LetterLeft";
import LetterRight from "./section/LetterRight";
import EditLetter from "./section/EditLetter";
import PDFLetter from "./section/PDFLetter";
import Price from "./section/Price";
import PaymentMethod from "./section/PaymentMethod";
import { ChevronDown, Lightbulb } from "lucide-react";
import { ColorV2, Feedback, Text } from "@adoptaunabuelo/react-components";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import Success from "./section/Success";
import SuccessRight from "./section/SuccessRight";
import moment from "moment";
import LetterImage from "./section/LetterImage";
import PriceLeft from "./section/PriceLeft";
import { GrowthBook } from "@growthbook/growthbook-react";
import PaymentMethodLeft from "./section/PaymentMethodLeft";
import Home from "./section/Home";

const AbsoluteDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;
	background: linear-gradient(72deg, #5963f6 0%, #cd59f6 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;
const SkipDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${ColorV2.border.neutralHigh};
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;
const PersonalRight = styled(SectionContainer)`
	${media.lessThan("medium")`
        display: none;
	`}
`;
const CorporateLogo = styled.img`
	height: 56px;
	width: auto;
`;
const ArrowAnimationView = styled.div`
	display: none;
	position: sticky;
	bottom: 0px;
	right: 0px;
	left: 0px;
	width: 100%;
	padding: 52px 0px 16px;
	background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
	align-items: flex-end;
	justify-content: center;
	${media.lessThan("medium")`
		display: flex;
    `}
`;
const BackgroundImage = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const Checkout = (props: Props) => {
	const wizard = useRef<WizardContainerRef>(null);
	const tips = Constant.letterTips();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const isScreen01: boolean = useSelector(
		(state: State) => state.screen.isScreen01
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const [user, setUser] = useState<UserScheme | undefined>(undefined);
	const [grandpa, setGrandpa] = useState<UserScheme | undefined>(undefined);
	const [letter, setLetter] = useState<{
		object?: LetterScheme;
		content: string;
		font: string;
		personalInfo: boolean;
		stamp: boolean;
		image?: string;
	}>({
		content: "",
		font: "Poppins",
		personalInfo: true,
		stamp: true,
	});
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [showMug, setShowMug] = useState(false);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [isSubscriptor, setIsSubscriptor] = useState(false);
	const [didSubscribe, setDidSubscribe] = useState(false);
	const [tip, setTip] = useState<string | undefined>(undefined);
	const [secondsRemaining, setSecondsRemaining] = useState(0);

	useEffect(() => {
		//init
		if (currentUser) {
			setUser(currentUser);
			//Check if user has all the data
			const hasData = checkUserData(currentUser);
			if (hasData) {
				if (props.prevLetter) {
					setLetter({
						content: props.prevLetter.content,
						font: "Poppins",
						personalInfo: true,
						stamp: true,
					});
					setGrandpa(props.prevLetter.grandpa);
				}
				wizard.current?.setStep(props.corporate ? 0 : 4);
			} else wizard.current?.setStep(props.corporate ? 0 : 3);
			Volunteer.isSubscriptor(currentUser.objectId).then((result) => {
				setIsSubscriptor(result.data);
			});
		} else {
			//Check if go to code step because user tried to login few seconds ago
			const tempUserString = localStorage.getItem("temp-login");
			if (tempUserString) {
				const tempUser = JSON.parse(tempUserString);
				const diffTime = moment().diff(moment(tempUser.date), "second");
				if (diffTime < 60) {
					setUser(tempUser.user);
					setSecondsRemaining(60 - diffTime);
					if (!props.corporate) wizard.current?.goNext();
				} else {
					localStorage.removeItem("temp-login");
				}
			}
		}
	}, []);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSecondsRemaining((prevSeconds) => prevSeconds - 1);
		}, 1000);
		if (secondsRemaining <= 0) {
			clearInterval(intervalId);
		}
		return () => clearInterval(intervalId);
	}, [secondsRemaining]);

	useEffect(() => {
		//TODO revisar cantidades
		if (product) {
			if (
				(product.charge === "annually" && product.price >= 150) ||
				(product.charge === "monthly" && product.price >= 25) ||
				(product.charge === "unique" && product.price >= 250)
			)
				setShowMug(false);
			else setShowMug(true);
		} else setShowMug(false);
	}, [product]);

	const checkUserData = (user: UserScheme) => {
		if (user.name && user.surname && user.email && user.birthday)
			return true;
		else return false;
	};

	const renderTipButton = () => (
		<AbsoluteDiv
			onClick={() => {
				const randomIndex = Math.floor(Math.random() * tips.length);
				const randomValue = tips[randomIndex];
				setTip(randomValue.title);
			}}
		>
			<Lightbulb color={"#6C62F6"} height={20} width={20} />
			Inpírate
		</AbsoluteDiv>
	);

	const renderSkipDonationButton = () => (
		<SkipDiv
			onClick={() => {
				setShowSkipModal(true);
			}}
		>
			No quiero colaborar
		</SkipDiv>
	);

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={
					"https://data.adoptaunabuelo.org/video/letter/skip_modal.webm"
				}
				text="Gracias a vuestras donaciones podemos hacer proyectos como este, en el que entregamos y leemos todas las cartas que nos habéis hecho llegar. En muchos casos es el único regalo que reciben por Navidad. Gracias por tu generosidad 💙"
				coorporate={props.corporate}
				onClick={() => {
					Track.track("skip donation modal click", {
						view: "letter",
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.track("skip donation modal close", {
						view: "letter",
					});
					setShowSkipModal(false);
					wizard.current?.goNext(2);
				}}
			/>
			<WizardContainer
				currentUser={user}
				ref={wizard}
				views={[
					//Corporate home view
					{
						hideNavBar: true,
						leftView: props.corporate && props.nLetters ? (
							<Home
								animated={true}
								amount={props.nLetters}
								corporate={props.corporate}
								onFinish={() => wizard.current?.goNext()}
							/>
						) : null,
						rightViewStyle: { maxHeight: "100vh" },
						rightView: (
							<BackgroundImage
								src={require("../../assets/images/letter/corporate-letter-background.webp")}
							/>
						),
					},
					//Login phone
					{
						hideBackButton: props.corporate ? false : true,
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: <></>,
						centralView: (
							<Login
								corporate={props.corporate}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setUser(result.data);
										setSecondsRemaining(60);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: <></>,
					},
					//SMS code
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: <></>,
						centralView: (
							<Code
								user={user}
								animated={true}
								countdown={secondsRemaining}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setUser(result.data);
										const hasData = checkUserData(
											result.data
										);
										if (hasData) wizard.current?.goNext(2);
										else wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: <></>,
						hideBackButton: secondsRemaining <= 0 ? false : true,
					},
					//Personal data
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftViewStyle: isMobile
							? { alignItems: "unset" }
							: undefined,
						leftView: user ? (
							<Personal
								user={user}
								corporate={props.corporate}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setUser(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
						rightView: !isMobile ? (
							<PersonalRight
								style={{ maxWidth: 388, paddingLeft: 100 }}
								title="¡Preséntate!"
								subtitle="Para que las personas mayores a las que escribas sepan quién les está enviando tanto cariño, necesitamos algunos datos sobre ti."
								animated={true}
							/>
						) : null,
					},
					//Search grandpa
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: !isMobile ? (
							<Stamp
								position="left"
								hobbies={grandpa?.data?.hobbies}
							/>
						) : null,
						centralView: user ? (
							<Search
								user={user}
								corporate={props.corporate}
								animated={true}
								prevGrandpa={grandpa}
								onGrandpaSearch={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setGrandpa(result.data);
										setLetter({
											...letter,
											content: "",
										});
									} else {
										setGrandpa(undefined);
									}
								}}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
						rightView: !isMobile ? (
							<Stamp
								position="right"
								hobbies={grandpa?.data?.hobbies}
							/>
						) : null,
						hideBackButton: true,
					},
					//Write letter
					{
						navigationRightView: isMobile
							? renderTipButton()
							: props.corporate?.logo && (
									<CorporateLogo
										src={props.corporate.logo.url}
									/>
							  ),
						leftViewStyle: isMobile ? { display: "none" } : {},
						leftView: grandpa ? (
							<LetterLeft grandpa={grandpa} />
						) : null,
						centralViewStyle: {
							width: isMobile ? "100%" : isScreen01 ? 500 : 600,
							height: isMobile
								? "calc(var(--doc-height) - 56px)"
								: "calc(var(--doc-height) - 72px)",
						},
						centralView:
							user && grandpa ? (
								<>
									{tip && (
										<Feedback
											isVisible={tip ? true : false}
											type={"custom"}
											style={{
												background:
													"linear-gradient(72deg, #5963F6 0%, #CD59F6 100%)",
												borderRadius: 12,
												top: isMobile ? 0 : -50,
												right: isMobile ? 16 : 0,
												left: isMobile ? 16 : 0,
												width: isMobile
													? "unset"
													: "100%",
												maxWidth: "unset",
												bottom: "unset",
											}}
											text={tip}
											closeAfter={5000}
											onClose={() => setTip(undefined)}
										/>
									)}
									<Letter
										user={user}
										grandpa={grandpa}
										animated={true}
										corporate={props.corporate}
										defaultValue={letter.content}
										TipButton={renderTipButton()}
										onFinish={(result) => {
											if (result.error) {
												setShowError({
													show: true,
													message: result.error,
												});
											} else if (result.data) {
												setLetter({
													...letter,
													content: result.data,
												});
												//Go to image if is a corporate letter
												if (
													props.corporate &&
													props.corporate.data &&
													props.corporate.data
														.letter &&
													props.corporate.data.letter
														.image
												)
													wizard.current?.goNext();
												else wizard.current?.goNext(2);
											}
										}}
									/>
								</>
							) : null,
						rightViewStyle: isMobile ? { display: "none" } : {},
						rightView: grandpa ? (
							<LetterRight hobbies={grandpa.data?.hobbies} />
						) : null,
					},
					//Add image step
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: <></>,
						centralView: (
							<LetterImage
								corporate={props.corporate}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else {
										setLetter({
											...letter,
											image: result.data,
										});
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: <></>,
					},
					//Edit letter PDF
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftViewStyle: {
							display: isMobile ? "none" : "flex",
						},
						leftView:
							user && grandpa ? (
								<EditLetter
									user={user}
									grandpa={grandpa}
									corporate={props.corporate}
									animated={true}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											if (
												isSubscriptor ||
												props.corporate
											)
												wizard.current?.goNext(3);
											else wizard.current?.goNext();
										}
									}}
								/>
							) : null,
						rightView:
							user && grandpa ? (
								<PDFLetter
									user={user}
									grandpa={grandpa}
									corporate={props.corporate}
									animated={true}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											if (isSubscriptor)
												wizard.current?.goNext(3);
											else wizard.current?.goNext();
										}
									}}
								/>
							) : undefined,
					},
					//Select price view
					{
						navigationRightView: isMobile
							? renderSkipDonationButton()
							: props.corporate?.logo && (
									<CorporateLogo
										src={props.corporate.logo.url}
									/>
							  ),
						containerStyle: { overflowY: "scroll" },
						leftView: (
							<>
								<Price
									growthbook={props.growthbook}
									options={["monthly", "annually", "unique"]}
									animated={true}
									view={"letter"}
									onSkipClick={() => setShowSkipModal(true)}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
											wizard.current?.goNext();
										}
									}}
								/>
								{isMobile && <PriceLeft />}
							</>
						),
						rightViewStyle: { overflowY: "scroll" },
						rightView: isMobile ? null : <PriceLeft />,
					},
					//Select payment method
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftViewStyle:
							!isMobile && showMug
								? {}
								: { alignItems: "center" },
						leftView:
							product && user ? (
								<PaymentMethod
									animated={true}
									product={product}
									user={user}
									corporate={props.corporate}
									showMug={showMug}
									view={"letter"}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setDidSubscribe(true);
											wizard.current?.goNext();
										}
									}}
									onProductChange={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
										}
									}}
								/>
							) : null,
						rightView:
							!isMobile && showMug && product ? (
								<PaymentMethodLeft
									animated={true}
									product={product}
									onProductChange={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
										}
									}}
								/>
							) : null,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: user ? (
							<>
								<Success
									animated={true}
									user={user}
									letter={letter.object}
									grandpa={grandpa}
									didSubscribe={didSubscribe}
									corporate={props.corporate}
								>
									{isMobile ? (
										<SuccessRight animated={true} />
									) : null}
								</Success>
								<ArrowAnimationView>
									<ChevronDown />
								</ArrowAnimationView>
							</>
						) : null,
						//TODO añadir animacion
						rightView: !isMobile ? (
							<SuccessRight animated={true} />
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {
	corporate?: CoorporateScheme;
	nLetters?: number,
	growthbook?: GrowthBook;
	prevLetter?: {
		content: string;
		grandpa: UserScheme;
	};
}
