import { useEffect, useRef, ComponentPropsWithoutRef, forwardRef, Ref, useImperativeHandle } from "react";
import moment from 'moment';

const LetterCanvas =  forwardRef((props: Props, ref: Ref<CanvasRef>) =>{

    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    useImperativeHandle(ref, () => ({
        getBase64(){
            if(canvasRef.current){
                return canvasRef.current.toDataURL();
            }
            else return undefined;
        }
    }));

    useEffect(() =>{
        const canvas = canvasRef.current;
        if(canvas){
            //Add text
            const ctx = canvas.getContext('2d');
            if(ctx){
                ctx.drawImage(props.image, 0, 0);
                ctx.font = "bold 82px Poppins";
                ctx.fillStyle = "rgba(0, 29, 61, 0.92)";
                ctx.textAlign="center";
                ctx.fillText(props.name, 540, 790);

                const ctx2 = canvas.getContext('2d');
                if(props.birthday && ctx2){
                    const years = moment().diff(props.birthday, 'years');
                    ctx2.font = "52px Poppins";
                    ctx.fillStyle = "rgba(0, 29, 61, 0.92)";
                    ctx2.textAlign="center";
                    ctx2.fillText('de '+years+' años', 540, 870);
                }
            }
        }
    }, [props.image]);

    return (
        <canvas 
            ref={canvasRef} 
            width={props.image.width} 
            height={props.image.height}
            {...props}
        />
    );
});
export default LetterCanvas;
export interface Props  extends ComponentPropsWithoutRef<"canvas">{
    image: HTMLImageElement,
    name: string,
    birthday?: string
}
export interface CanvasRef{
    getBase64: () => string | undefined
}