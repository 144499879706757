import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Modal, Text, ProgressBar, ColorV2 } from '@adoptaunabuelo/react-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`
const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    align-items: center;
`
const Col = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ReliefModal = (props: Props) =>{

    const isMobile = window.screen.width <= 768;
    const [ priceAmount, setPriceAmount ] = useState('0');
    const [ reliefAmount, setReliefAmount ] = useState('0');

    useEffect(() =>{
        getReliefAmount(props.price, props.charge);
    }, [props.price, props.charge]);

    const getReliefAmount = (price: number, charge: string) =>{
        const totalAmount = charge === 'monthly' ? price * 12 : price;
        const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
        const reliefSection2 = totalAmount > 250 ? totalAmount-250 : 0;
        const relief = reliefSection1*0.8 + reliefSection2*0.40;
        setPriceAmount((price*(charge === 'monthly' ? 12 : 1) - relief).toFixed(2));
        setReliefAmount(relief.toFixed(2));
    }

    return (
        <Modal
            isVisible={props.isVisible}
            type={isMobile ? 'full-screen' : 'default'}
            onClose={props.onClose}
        >
            <Container>
                <Text type='h4' weight='semibold' style={{marginBottom: 4}}>
                    Tu donación es deducible
                </Text>
                <Text type='p'>
                    En tu próxima declaración de la Renta podrás desgravarte la cantidad que hayas donado a la Fundación Adopta Un Abuelo siguiendo los estos porcentajes:
                </Text>
                <Section
                    style={{marginTop: 32, padding: 0}}
                >
                    <Row>
                        <Col>
                            <Text type='h1' style={{color: ColorV2.border.secondary }} weight='semibold'>
                                80%
                            </Text>
                            <Text type='p2' weight='medium'>
                                de los primeros 250 €
                            </Text>
                        </Col>
                        <Col>
                            <Text type='h1' style={{color: ColorV2.border.secondarySoft }} weight='semibold'>
                                40%
                            </Text>
                            <Text type='p2' weight='medium'>
                                del resto*
                            </Text>
                        </Col>
                    </Row>
                    <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 16}}>
                        *La deducción pasa a ser del <span style={{fontSize: 13, fontWeight: 600}}>45%</span> si colaboras en Adopta Un Abuelo con una donación igual o superior durante al menos 3 años.
                    </Text>
                </Section>
                <Section
                    style={{backgroundColor: ColorV2.surface.background, marginTop: 32}}
                >
                    <Text type='p'>
                        Si donas {props.price} € {props.charge === 'monthly' ? 'al mes durante un año' : 'este año'}, tras la declaración de la renta:
                    </Text>
                    <ProgressBar
                        style={{height: 8, width: '100%', marginTop: 24, marginBottom: 8}}
                        progress={[
                            {
                                value: parseFloat(reliefAmount)/props.price*100,
                                color: ColorV2.surface.primaryHard
                            },
                            {
                                value: parseFloat(priceAmount)/props.price*100,
                                color: ColorV2.surface.primary
                            }
                        ]}
                    />
                    <Row>
                        <Col
                            style={{alignItems: 'flex-start'}}
                        >
                            <Text type='p'>
                                Te devolverán
                            </Text>
                            <Text type={'h4'} weight='semibold'>
                                {reliefAmount.split('.')[0]}
                                <span style={{fontSize: 18, fontWeight: 500}}>
                                    ,{reliefAmount.split('.')[1]}€
                                </span>
                            </Text>
                        </Col>
                        <Col
                            style={{alignItems: 'flex-end'}}
                        >
                            <Text type='p' style={{color: ColorV2.text.primary}}>
                                Solo te costaría
                            </Text>
                            <Text type={'h4'} weight='semibold' style={{color: ColorV2.text.primary}}>
                                {priceAmount.split('.')[0]}
                                <span style={{fontSize: 18, fontWeight: 500}}>
                                    ,{priceAmount.split('.')[1]}€
                                </span>
                            </Text>
                        </Col>
                    </Row>
                </Section>
                <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 16}}>
                    No aplica a País Vasco y Navarra, donde la deducción es del 20 y el 25%.
                </Text>
                <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 6}}>
                    El límite de la deducción sobre la base liquidable se mantiene en el 10%.
                </Text>
                <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 6}}>
                    Adopta Un Abuelo consta en el registro de fundaciones del Ministerio de Justicia con el nº 2796 y CIF: G56246788.
                </Text>
            </Container>
        </Modal>
    )
}
export default ReliefModal;
export interface Props{
    isVisible: boolean,
    price: number,
    charge: string
    onClose: () => void
}