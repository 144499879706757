import { ChallengeScheme } from "client-v2";

import Cell from "./Cell";
import { Text, ColorV2, Button } from "@adoptaunabuelo/react-components";

const LetterCell = (props: Props) =>{
    return(
        <Cell
            challenge={props.challenge}
            backgroundColor={'#E1E1E3'}
            titleColor={ColorV2.text.neutralHard}
        >
            <Text type='h6' style={{marginTop: 24, marginBottom: 40}}>
                Envía una carta a los abuelos que viven solos en residencias.
            </Text>
            <Button 
                style={{width: 'fit-content'}}
                onClick={() => window.open('https://adoptaunabuelo.org/una-carta-para-un-abuelo', "_blank")}
            >
                ¡Quiero enviar mi carta!
            </Button>
        </Cell>
    )
}
export default LetterCell;
export interface Props{
    challenge: ChallengeScheme
}