import { CoorporateScheme, UserScheme } from "client-v2";
import SectionContainer from "./SectionContainer";
import { TextArea, Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { ReactNode, useEffect, useState } from "react";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Letter = (props: LetterProps) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
					animation: [
						props.corporate.color.main,
						props.corporate.color.secondary,
					],
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
					animation: ["#00315C", "#005AA8"],
			  };
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const [letter, setLetter] = useState<string | undefined>(undefined);

	useEffect(() => {
		setLetter(props.defaultValue);
	}, [props.defaultValue]);

	const onNextClick = () => {
		if (letter && letter.length <= 0)
			props.onFinish({
				error: "Escribe una carta antes de continuar.",
			});
		else
			props.onFinish({
				data: letter,
			});
	};

	return (
		<SectionContainer
			style={{
				padding: 0,
				flexDirection: "column",
			}}
			childrenProps={{
				style: {
					flex: 1,
				},
			}}
			animated={props.animated}
			buttonProps={{
				LeftView: isMobile && (
					<Footer
						grandpa={props.grandpa}
						corporate={props.corporate}
					/>
				),
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
					marginBottom: isMobile ? 16 : 40,
					marginLeft: isMobile ? 8 : 48,
					marginRight: isMobile ? 24 : 48,
					width: isMobile ? "fit-content" : undefined,
					position: "relative",
				},
				backgroundColor: isMobile ? "transparent" : "white",
				disabled: letter && letter.length > 0 ? false : true,
				children: "Siguiente",
				onClick: onNextClick,
			}}
		>
			{isMobile && (
				<Text
					type="h3"
					weight="semibold"
					style={{ margin: "8px 24px 24px" }}
				>
					Escribe tu carta
				</Text>
			)}
			<TextArea
				style={{
					height: isMobile
						? "calc(var(--doc-height) - 88px - 64px - 56px)"
						: "calc(var(--doc-height) - 112px - 72px - 32px)",
					width: isMobile ? "100vw" : "100%",
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					borderTopRightRadius: isMobile ? 0 : 12,
					borderTopLeftRadius: isMobile ? 0 : 12,
					marginTop: isMobile ? 0 : 32,
				}}
				type="edit"
				placeholder="Escribe tu carta"
				value={props.defaultValue}
				ToolbarButton={props.TipButton}
				onChange={(value) => {
					const prevLetterString =
						localStorage.getItem("letter") || "{}";
					const prevLetter = JSON.parse(prevLetterString);
					localStorage.setItem(
						"letter",
						JSON.stringify({
							...prevLetter,
							content: value,
						})
					);
					setLetter(value);
				}}
			/>
		</SectionContainer>
	);
};
export default Letter;
export interface LetterProps {
	user: UserScheme;
	grandpa: UserScheme;
	corporate?: CoorporateScheme;
	animated?: boolean;
	defaultValue?: string;
	TipButton?: ReactNode;
	onFinish: (result: { data?: string; error?: string }) => void;
}
