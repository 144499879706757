import { useRef, useState } from "react";
import LoginSection, { LoginRef } from "../../../components/section/Login";
import SectionContainer from "./SectionContainer";
import { UserScheme, Auth, CoorporateScheme, ErrorScheme } from "client-v2";
import ReactPixel from "react-facebook-pixel";
import { ColorV2 } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Login = (props: LoginProps) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const loginSection = useRef<LoginRef>(null);
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
			  };
	const [loading, setLoading] = useState(false);

	const onNextClick = () => {
		const result = loginSection.current?.getData();
		if (result && result.phone) {
			setLoading(true);
			Auth.register({
				phone: result.phone,
				coorporateId: props.corporate?.objectId,
			})
				.then((result) => {
					if (!result.data.isLogin) {
						//Track on Facebook
						ReactPixel.track("fb_mobile_activate_app");
						localStorage.setItem(
							"temp-login",
							JSON.stringify({
								date: new Date(),
								user: result.data.User,
							})
						);
						props.onFinish({
							data: result.data.User,
						});
					} else {
						const user = result.data.User;
						Auth.sendCode({
							userId: user.objectId,
						})
							.then((result) => {
								setLoading(false);
								localStorage.setItem(
									"temp-login",
									JSON.stringify({
										date: new Date(),
										user: result.data,
									})
								);
								props.onFinish({
									data: result.data,
								});
							})
							.catch((error: ErrorScheme) => {
								setLoading(false);
								props.onFinish({
									error: error.message,
								});
							});
					}
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onFinish({
						error: error.message,
					});
				});
		}
	};

	return (
		<SectionContainer
			title="¡Empezamos con tu teléfono!"
			subtitle="Te enviaremos un SMS con un código de verificación."
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				children: "Siguiente",
				loading: loading,
				onClick: onNextClick,
			}}
		>
			<LoginSection
				ref={loginSection}
				country={
					appLocation && appLocation.country_code
						? appLocation.country_code
						: "ES"
				}
				onEnter={onNextClick}
			/>
		</SectionContainer>
	);
};
export default Login;
export interface LoginProps {
	corporate?: CoorporateScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
