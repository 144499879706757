import { useEffect, useState } from "react";
import { Track, ChallengeScheme, UserScheme } from "client-v2";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Header from "../../components/section/Header";
import Info from "./section/Info";
import Resume from "../../components/section/ResumeDetail";
import Container from "../../components/container/Container";
import { ColorV2 } from "@adoptaunabuelo/react-components";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

const Detail = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setShowSuccess } = bindActionCreators(actionsCreators, dispatch);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const loading = useSelector((state: State) => state.modals.loading);
	const sashidoUrl =
		process.env.NODE_ENV === "production"
			? "https://sqe78ajvu1g4l9vzisq7gst60ulsui.files-sashido.cloud"
			: "https://zmadwoa9sdn103iq5yeq37v173sz60.files-sashido.cloud";

	const [pastDate, setPastDate] = useState(false);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		//Check if past date
		const diff = moment(props.challenge.endDate.iso).diff(moment(), "days");
		if (diff <= 0) setPastDate(true);

		//Check if goal completed
		const restAmount = props.challenge.goal - props.challenge.amount;
		if (restAmount <= 0) setCompleted(true);
	}, []);

	const onNextClick = () => {
		if (pastDate || completed) {
			navigate("/donacion/registro");
		} else {
			//Track on mixpanel
			Track.track("donation progress init", {
				view: "challenge",
			});
			navigate("/reto/registro/?id=" + props.challenge.objectId, {
				state: {
					challenge: props.challenge,
				},
			});
		}
	};

	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/reto/?id=" + props.challenge.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		} else if (op.id === "url") {
			navigator.clipboard.writeText(url);
			setShowSuccess({
				show: true,
				message: "Se ha copiado el enlace en tu portapapeles",
			});
		}
	};

	return (
		<Container
			loading={loading}
			headerProps={{
				user: currentUser,
				transparent: true,
			}}
			footerProps={{
				style: { backgroundColor: "white" },
				buttonProps: {
					style: {
						width: "100%",
						backgroundColor:
							props.challenge.type === "rose" &&
							ColorV2.surface.redMedium,
					},
					children:
						props.challenge.type === "signature"
							? "Quiero firmar"
							: props.challenge.type === "rose"
							? "Quiero regalar una rosa"
							: "Quiero colaborar",
					onClick: onNextClick,
				},
			}}
			RightView={
				<Resume
					challenge={props.challenge}
					supporters={props.supporters}
					onClick={onNextClick}
					onShareClick={onShareClick}
				/>
			}
		>
			<Header
				title={props.challenge.title}
				image={`${sashidoUrl}/${props.challenge.image.url
					.split("/")
					.pop()}`}
				video={props.challenge.video}
			/>
			<Info challenge={props.challenge} supporters={props.supporters} />
		</Container>
	);
};
export default Detail;
export interface Props {
	challenge: ChallengeScheme;
	supporters: Array<any>;
}
