import styled from "styled-components";
import SectionContainer from "./SectionContainer";
import { Text, ColorV2, Button } from "@adoptaunabuelo/react-components";
import media from "styled-media-query";
import { ErrorScheme, Product, ProductScheme } from "client-v2";
import { useState } from "react";

const isScreen01: any = "1350px";
const isScreen02: any = "1050px";
const SectionContainerStyled = styled(SectionContainer)`
	margin-left: 8%;
	width: 485px;
	padding: 40px 32px;
	${media.lessThan(isScreen01)`
        width: 425px;
        margin-left: 6%;
    `}
	${media.lessThan(isScreen02)`
        width: 375px;
        margin-left: 4%;
        padding: 40px 24px;
    `}
	${media.lessThan(isScreen02)`
        width: unset;
        margin-left: 0px;
        border-radius: 0px;
    `}
	${media.lessThan(isScreen02)`
		padding: 32px 24px 16px;
	`}
`;
const MugBanner = styled.div`
	display: flex;
	background-color: ${ColorV2.surface.brown};
	border-radius: 12px;
	margin-top: 32px;
`;
const PresentImage = styled.img`
	width: 160px;
	object-fit: cover;
	object-position: left;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 12px 24px 16px;
	gap: 12px;
`;

const PaymentMethodLeft = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);

	const updateProduct = async () => {
		//TODO actualizar los precios
		setLoading(true);
		if (
			props.product.charge === "annually" ||
			props.product.charge === "monthly"
		) {
			Product.get({
				active: true,
				price:
					props.product.charge === "annually"
						? 150
						: props.product.charge === "monthly"
						? 25
						: 250,
				charge: props.product.charge,
			})
				.then((result) => {
					setProduct(result.data[0]);
					setLoading(false);
					setSuccess(true);
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					props.onProductChange({ error: e.message });
				});
		} else if (props.product.charge === "unique") {
			setProduct({
				objectId: "unique",
				charge: "unique",
				price: 250,
			} as ProductScheme);
			setLoading(false);
			setSuccess(true);
		}
	};

	return (
		<SectionContainerStyled
			animated={props.animated}
			title="¡Todavía estás a tiempo!"
		>
			<MugBanner>
				<Col>
					<Text type="h6" weight="semibold">
						Aumenta tu donación y llévate una taza única de Miki
						Nöell.
					</Text>
					<Text type="p2">
						De paso estarás ayudándonos a acompañar a más abuelos y
						combatir con más fuerza la soledad no deseada.
					</Text>
					<Button
						size="small"
						style={{
							backgroundColor: success
								? ColorV2.surface.green
								: "white",
							color: ColorV2.text.neutralHard,
							width: "fit-content",
                            minWidth: 140
						}}
						loading={loading}
						success={success}
						onClick={updateProduct}
						onSuccess={() =>
							props.onProductChange({ data: product })
						}
					>
						Quiero la taza
					</Button>
				</Col>
				<PresentImage
					src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
				/>
			</MugBanner>
		</SectionContainerStyled>
	);
};
export default PaymentMethodLeft;
export interface Props {
	animated?: boolean;
	product: ProductScheme;
	onProductChange: (result: { data?: ProductScheme; error?: string }) => void;
}
