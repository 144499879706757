import { useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import {
	Corporate,
	CoorporateScheme,
	ErrorScheme,
	UserScheme,
	Letter,
} from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import { Feedback } from "@adoptaunabuelo/react-components";
import Checkout from "../views/letter/Checkout";
import Loading from "../components/modal/Loading";
import { GrowthBook } from "@growthbook/growthbook-react";

const FeedbackStyled = styled(Feedback)`
	right: 24px !important;
	left: unset !important;
	${media.lessThan("small")`
        top: 64px;
        left: 24px !important;
        bottom: unset !important;
	`}
`;

const LetterLayout = (props: LetterLayoutProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const error = useSelector((state: State) => state.modals.error);

	const [loading, setLoading] = useState(true);
	const [corporate, setCorporate] = useState<CoorporateScheme | undefined>(
		undefined
	);
	const [nLetters, setNLetters] = useState(0);
	const [prevLetter, setPrevLetter] = useState<
		| {
				content: string;
				grandpa: UserScheme;
		  }
		| undefined
	>(undefined);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = () => {
		getCorporate();
	};

	const getCorporate = async () => {
		const id = searchParams.get("id");
		if (id) {
			Corporate.getById(id)
				.then((result) => {
					//TODO obtener número de cartas
					Letter.count({ corporateId: result.data.objectId, createdAt: { min: new Date("2024-10-01"), max: new Date("2025-02-01")} })
						.then((result2) => {
							setNLetters(result2.pagination.count);
							setCorporate(result.data);
							getPrevLetter();
						})
						.catch((error: ErrorScheme) => {
							setLoading(false);
							setShowError({
								show: true,
								message: error.message,
							});
						});
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		} else {
			getPrevLetter();
		}
	};

	const getPrevLetter = () => {
		//Get the prev letter
		const prevLetterString = localStorage.getItem("letter");
		if (prevLetterString) {
			const prevLetter = JSON.parse(prevLetterString);
			setPrevLetter(prevLetter);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<FeedbackStyled
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<Routes>
				<Route
					path="/"
					element={
						loading ? (
							<Loading isVisible={true} />
						) : (
							<Checkout
								nLetters={nLetters}
								corporate={corporate}
								prevLetter={prevLetter}
								growthbook={props.growthbook}
							/>
						)
					}
				/>
			</Routes>
		</>
	);
};

export default LetterLayout;
export interface LetterLayoutProps {
	growthbook?: GrowthBook;
}
