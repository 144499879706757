import {
	useEffect,
	useState,
	useRef,
	ReactElement,
	CSSProperties,
} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { useNavigate } from "react-router-dom";
import {
	Auth,
	User,
	CoorporateScheme,
	UserScheme,
	ErrorScheme,
} from "client-v2";
import ReactPixel from "react-facebook-pixel";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import HeaderNav, { HeaderProps } from "./Header";
import { Button, Text, Color, ColorV2 } from "@adoptaunabuelo/react-components";
import Footer from "./Footer";
import { ArrowLeft, X } from "lucide-react";
import PaycardLogo from "../../assets/logo/Paycard";
import ReCaptchaButton from "../button/ReCaptchaButton";

import Login, { LoginRef } from "../section/Login";
import Personal, { PersonalRef } from "../section/Personal";
import Code, { CodeRef } from "../section/Code";

const screenSize1: any = "1300px";
const screenSize2: any = "1150px";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	min-height: var(--doc-height);
	background-color: ${ColorV2.surface.background};
`;
const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	max-width: 1280px;
	padding: 0px 10% 80px;
	height: 100%;
	gap: 8%;
	width: -webkit-fill-available;
	width: -moz-available;
	justify-content: center;
	overflow: hidden;
	${media.lessThan(screenSize1)`
        padding: 0px 5% 80px;
    `}
	${media.lessThan(screenSize2)`
        padding: 0px 48px 80px;
        gap: 5%;
    `}
    ${media.lessThan("medium")`
        padding: 0px 32px 74px;
        gap: 0px;
    `}
    ${media.lessThan("small")`
        padding: 0px 24px 74px;
    `}
`;
const LeftView = styled.div<{ hidden?: boolean }>`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in, transform 0.6s ease-out;
	transform: ${(props) =>
		props.hidden ? "translateY(20px)" : "translateY(0px)"};
	max-width: 50%;
	${media.lessThan(screenSize1)`
        max-width: 55%;
    `}
	${media.lessThan(screenSize2)`
        max-width: 60%;
    `}
    ${media.lessThan("medium")`
        max-width: 100%;
    `}
    ${media.lessThan("small")`
        max-width: 100%;
    `}
`;
const RightView = styled.div<{ hidden?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 40%;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const HeaderDesktop = styled.div`
	display: flex;
	flex-direction: column;
`;
const NextButton = styled(Button)`
	${media.lessThan("medium")`
        display: none !important;
    `}
`;
const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 12px;
	justify-content: space-between;
	align-items: center;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const BackView = styled.div`
	${media.lessThan("small")`
        display: none;
    `}
`;

const Wizard = (props: Props) => {
	const loginIds = ["login", "code", "login2"];

	const dispatch = useDispatch();
	const { setCurrentUser } = bindActionCreators(actionsCreators, dispatch);
    const isMobile: boolean = useSelector((state:State)=>state.screen.isMobile);
	const currentUser = useSelector((state:State)=>state.user.currentUser);

	const navigate = useNavigate();
	const loginStep = useRef<LoginRef>(null);
	const personalStep = useRef<PersonalRef>(null);
	const codeStep = useRef<CodeRef>(null);

	const [activeHeader, setActiveHeader] = useState(false);
	const [show, setShow] = useState(false);
	const [index, setIndex] = useState(0);
	const [step, setStep] = useState(0);
	const [ids, setIds] = useState<Array<string>>([]);
	const [loading, setLoading] = useState(props.buttonProps?.loading);
	const [tempUser, setTempUser] = useState<UserScheme | undefined>(undefined);
	const [disableBack, setDisableBack] = useState(false);
	const [countdown, setCountdown] = useState(30);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		setShow(false);
		delay(600).then(() => {
			setShow(true);
			setStep(index);
		});
	}, [index]);

	useEffect(() => {
		setLoading(props.buttonProps?.loading);
	}, [props.buttonProps?.loading]);

	useEffect(() => {
		//Generate the views ids
		const temp = props.LeftViews.map((item) => "view");
		if (props.loginIndex !== undefined) {
			temp.splice(props.loginIndex, 0, ...loginIds);
			setIds(temp);
		} else {
			setIds(temp);
		}
	}, [props.loginIndex, props.LeftViews]);

	useEffect(() => {
		//Step is before login process
		if (props.loginIndex !== undefined && props.step < props.loginIndex) {
			setIndex(props.step);
		} else {
			//User is logged, skip the login process
			if (currentUser) {
				if (
					currentUser.name &&
					currentUser.surname &&
					currentUser.email
				) {
					setIndex(props.step + loginIds.length);
				} else {
					setIndex(props.step + loginIds.length - 1);
				}
			}
			//User is not logged
			else {
				setIndex(props.step);
			}
		}
	}, [props.step, currentUser]);

	const delay = (ms: number) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	const handleScroll = (e: any) => {
		if (window.scrollY > 100) setActiveHeader(true);
		else setActiveHeader(false);
	};

	const onNextClick = () => {
		//Login / register the user
		if (index === props.loginIndex) {
			const newData = loginStep.current?.getData();
			if (newData) {
				registerVolunteer(newData);
			}
		}
		//Code
		else if (index - 1 === props.loginIndex) {
			const newData = codeStep.current?.getData();
			if (newData && newData.code) {
				onCodeFinish(newData.code);
			} else {
				props.onError &&
					props.onError("Debes introducir un código válido");
			}
		}
		//Personal data
		else if (index - 2 === props.loginIndex) {
			const newData = personalStep.current?.getData();
			if (newData) {
				finishRegister(newData.name, newData.surname, newData.email);
			}
		}
		//View process
		else {
			props.buttonProps?.onClick();
		}
	};

	const onBackClick = () => {
		setCountdown(60);
		//If is first step, navigate back
		if (index === 0) navigate(-1);
		else {
			//If is an user logged and step is previous to login process
			if (
				currentUser &&
				props.loginIndex !== undefined &&
				props.loginIndex + loginIds.length === index
			) {
				if (index - (loginIds.length + 1) < 0) navigate(-1);
				else {
					setIndex(index - (loginIds.length + 1));
					props.onStepChange(index - (loginIds.length + 1));
				}
			}
			//User is in the login process
			else if (
				!currentUser &&
				props.loginIndex !== undefined &&
				(index >= props.loginIndex ||
					index <= props.loginIndex + loginIds.length)
			) {
				setIndex(
					props.loginIndex - loginIds.length < 0
						? 0
						: props.loginIndex - loginIds.length
				);
				props.onStepChange(
					props.loginIndex - loginIds.length < 0
						? 0
						: props.loginIndex - loginIds.length
				);
			} else {
				const newIndex =
					props.loginIndex !== undefined && step <= props.loginIndex
						? step
						: step - loginIds.length;
				setIndex(newIndex - 1);
				props.onStepChange(newIndex - 1);
			}
		}
	};

	const registerVolunteer = async (data: any) => {
		setLoading(true);
		Auth.register({
			name: data.name,
			surname: data.surname,
			email: data.email,
			phone: data.phone,
			coorporateId: props.coorporate?.objectId,
		})
			.then((result) => {
				if (!result.data.isLogin) {
					//Track on Facebook
					ReactPixel.track("fb_mobile_activate_app");
					setTempUser(result.data.User);
					setLoading(false);
					setIndex(index + 1);
					onDisableBack();
				} else {
					const user = result.data.User;
					Auth.sendCode({
						userId: user.objectId,
					})
						.then((result) => {
							setTempUser(user);
							setLoading(false);
							setIndex(index + 1);
							onDisableBack();
						})
						.catch((error: string) => {
							setLoading(false);
							props.onError && props.onError(error);
						});
				}
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				props.onError && props.onError(error.message);
			});
	};

	const onDisableBack = () => {
		setDisableBack(true);
		const interval = setInterval(() => {
			setCountdown((prev) => {
				const value = prev - 1;
				if (value <= 0) {
					clearInterval(interval);
					setDisableBack(false);
				}
				return prev - 1;
			});
		}, 1000);
	};

	const onCodeFinish = async (code: string) => {
		if (tempUser) {
			setLoading(true);
			Auth.login(tempUser.username, code)
				.then((result) => {
					if (tempUser.email && tempUser.name && tempUser.surname) {
						setIndex(index + 2);
						setCurrentUser && setCurrentUser(tempUser);
						props.onLogin && props.onLogin(tempUser);
					} else setIndex(index + 1);
					setCountdown(30);
					setDisableBack(false);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onError && props.onError(error.message);
				});
		}
	};

	const finishRegister = async (
		name: string,
		surname: string,
		email: string
	) => {
		if (tempUser) {
			setLoading(true);
			User.set(tempUser.objectId, {
				name: name,
				surname: surname,
				email: email,
				internalData: {
					tags: props.tag,
				},
			})
				.then((result) => {
					setLoading(false);
					setCurrentUser && setCurrentUser(result.data);
					props.onLogin && props.onLogin(result.data);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onError && props.onError(error.message);
				});
		}
	};

	function nextButtonProps(): any {
		const leftViewProps =
			props.loginIndex === 0 && step === 0
				? undefined
				: props.LeftViews[
						props.loginIndex !== undefined &&
						step <= props.loginIndex
							? step
							: step - loginIds.length
				  ]
				? props.LeftViews[
						props.loginIndex !== undefined &&
						step <= props.loginIndex
							? step
							: step - loginIds.length
				  ].props
				: undefined;
		const request =
			leftViewProps &&
			leftViewProps.buttonProps &&
			(leftViewProps.buttonProps.request === "apple_pay" ||
				leftViewProps.buttonProps.request === "google_pay")
				? leftViewProps.buttonProps.request
				: undefined;
		const requestBrand: any = request ? request + "_button" : undefined;
		const { style, ...restButtonProps } = props.buttonProps
			? props.buttonProps
			: { style: undefined };

		return {
			style: {
				zIndex: 100,
				width:
					loading || props.buttonProps?.success
						? 50
						: ids[step] === "view"
						? leftViewProps && leftViewProps.buttonProps
							? leftViewProps.buttonProps.width
							: 120
						: 120,
				backgroundColor:
					leftViewProps &&
					leftViewProps.buttonProps &&
					(leftViewProps.buttonProps.request === "apple_pay" ||
						leftViewProps.buttonProps.request === "google_pay")
						? "black"
						: undefined,
				...leftViewProps?.buttonProps?.style,
				...style,
			},
			...restButtonProps,
			icon:
				request && requestBrand ? (
					<PaycardLogo
						style={{ marginRight: 8 }}
						brand={requestBrand}
					/>
				) : undefined,
			iconPosition: "right",
			loading: loading,
			animationDelay: 400,
			animationTime: 0.2,
			children:
				ids[step] === "view"
					? leftViewProps && leftViewProps.buttonProps
						? leftViewProps.buttonProps.children
						: "Siguiente"
					: "Siguiente",
			onClick: onNextClick,
		};
	}

	const renderRightView = () => {
		const View =
			ids.length === step + 1
				? props.RightViews[props.RightViews.length - 1]
				: props.RightViews.length === props.LeftViews.length + 3
				? props.RightViews[step]
				: props.RightViews[0];
		return View ? <RightView hidden={!show}>{View}</RightView> : null;
	};

	const renderFooter = () => {
		const buttonProps = nextButtonProps();
		return buttonProps.style &&
			buttonProps.style.display &&
			buttonProps.style.display === "none" ? undefined : (
			<Footer
				buttonProps={{
					...buttonProps,
					recaptcha: ids[step] === "login" ? true : false,
				}}
				skipButtonProps={props.skipButtonProps}
				childrenPosition={"left"}
			>
				{props.FooterChildren}
			</Footer>
		);
	};

	return (
		<Container style={props.style}>
			<HeaderNav
				user={currentUser}
				active={activeHeader}
				childrenPosition={"left"}
				onBackClick={onBackClick}
				disableBack={disableBack}
				showClose={ids.length !== step + 1 ? false : true}
				{...props.headerProps}
			>
				{props.headerProps?.children}
			</HeaderNav>
			{ids.length !== step + 1 && renderFooter()}
			<Content style={props.contentStyle}>
				<LeftView hidden={!show}>
					{ids.length !== step + 1 ? (
						<HeaderDesktop>
							<BackView>
								<Button
									design="image"
									style={{
										height: 44,
										width: 44,
										marginLeft: -10,
									}}
									icon={
										<ArrowLeft
											color={
												props.style?.color
													? props.style.color
													: Color.text.full
											}
										/>
									}
									disabled={disableBack}
									onClick={onBackClick}
								/>
							</BackView>
							<Text
								type="h3"
								weight="semibold"
								style={{
									color: props.style?.color
										? props.style.color
										: Color.text.full,
								}}
							>
								{ids[step] === "login"
									? "¡Empezamos con tu teléfono!"
									: ids[step] === "code"
									? "Código de verificación"
									: ids[step] === "login2"
									? "Datos personales"
									: props.titles &&
									  props.titles[
											props.loginIndex !== undefined &&
											step <= props.loginIndex
												? step
												: step - loginIds.length
									  ]}
							</Text>
							<Text
								type="p"
								style={{
									marginTop: 8,
									color: props.style?.color
										? props.style.color
										: Color.text.full,
								}}
							>
								{ids[step] === "login"
									? "Con tu número de teléfono podremos identificar tu usuario y enviarte un código de confirmación."
									: ids[step] === "code"
									? "Te hemos enviado un SMS con tu código de verificación" +
									  (tempUser && " al " + tempUser.phone) +
									  ". Ten paciencia, puede tardar unos minutos."
									: ids[step] === "login2"
									? ""
									: props.subtitles &&
									  props.subtitles[
											props.loginIndex !== undefined &&
											step <= props.loginIndex
												? step
												: step - loginIds.length
									  ]}
							</Text>
						</HeaderDesktop>
					) : (
						<HeaderDesktop>
							<BackView>
								<Button
									design="image"
									style={{
										height: 44,
										width: 44,
										marginLeft: -10,
									}}
									icon={
										<X
											color={
												props.style?.color
													? props.style.color
													: Color.text.full
											}
										/>
									}
									onClick={() =>
										window.open(
											"https://adoptaunabuelo.org"
										)
									}
								/>
							</BackView>
						</HeaderDesktop>
					)}
					{ids[step] === "login" ? (
						<Login
							ref={loginStep}
							country={
								props.location
									? props.location.country_code
									: "ES"
							}
							onEnter={onNextClick}
						/>
					) : ids[step] === "login2" ? (
						<Personal ref={personalStep} />
					) : ids[step] === "code" ? (
						<Code
							ref={codeStep}
							countdown={countdown}
							loading={loading}
							onCodeChange={(code: string) => onCodeFinish(code)}
						/>
					) : (
						props.LeftViews[
							props.loginIndex !== undefined &&
							step <= props.loginIndex
								? step
								: step - loginIds.length
						]
					)}
					{ids.length !== step + 1 && (
						<ButtonRow>
							{props.buttonProps &&
								(ids[step] === "login" ? (
									<ReCaptchaButton
										action="login"
										{...nextButtonProps()}
									/>
								) : (
									<NextButton {...nextButtonProps()} />
								))}
							{props.skipButtonProps && (
								<Button
									{...props.skipButtonProps}
									design={"call-to-action"}
									style={{
										display: isMobile ? "none" : "block",
										color: Color.text.high,
									}}
									loading={loading}
								/>
							)}
						</ButtonRow>
					)}
				</LeftView>
				{renderRightView()}
			</Content>
		</Container>
	);
};
export default Wizard;
export interface Props {
	style?: CSSProperties;
	contentStyle?: CSSProperties;
	tag:
		| "register-letter"
		| "register-signature"
		| "register-donation"
		| "register-volunteer"
		| "register-dream"
		| "register-birthday"
		| "register-event";
	location: any;
	step: number;
	headerProps?: HeaderProps;
	titles?: Array<string | undefined>;
	subtitles?: Array<string>;
	LeftViews: Array<ReactElement>;
	RightViews: Array<ReactElement | undefined>;
	loginIndex?: number;
	buttonProps?: {
		style?: CSSProperties;
		success?: boolean;
		loading?: boolean;
		onClick: () => void;
		onSuccess?: () => void;
	};
	skipButtonProps?: {
		children: string;
		onClick: () => void;
	};
	FooterChildren?: ReactElement;
	coorporate?: CoorporateScheme;
	onStepChange: (step: number) => void;
	onLogin?: (user: UserScheme) => void;
	onError?: (error: string) => void;
}
