import { Button, ColorV2 } from "@adoptaunabuelo/react-components";
import { UserScheme } from "client-v2";
import { ArrowLeft } from "lucide-react";
import React, {
	CSSProperties,
	forwardRef,
	Ref,
	useImperativeHandle,
	useState,
} from "react";
import styled from "styled-components";
import media from "styled-media-query";

const MainContainer = styled.div`
	display: flex;
	height: var(--doc-height);
	width: 100vw;
	overflow: hidden;
	flex-direction: column;
	background-color: ${ColorV2.surface.background};
`;
const NavBar = styled.div<{ $hideBackButton?: boolean }>`
	display: flex;
	height: 72px;
	padding: 0px 24px;
	align-items: center;
	justify-content: ${(props) =>
		props.$hideBackButton ? "flex-end" : "space-between"};
	${media.lessThan("medium")`
        padding: 0px 24px 0px 8px;
		height: 56px;
    `}
`;
const DataContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const LeftContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: flex-end;
`;
const RightContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
`;
const CentralContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 388px;
	${media.lessThan("medium")`
		width: 100%;
    `}
`;

const WizardContainer = forwardRef(
	(props: WizardContainerProps, ref: Ref<WizardContainerRef>) => {
		const [step, setStep] = useState(0);

		useImperativeHandle(ref, () => ({
			goBack(steps?: number) {
				return onBackClick(steps);
			},
			goNext(steps?: number) {
				return onNextClick(steps);
			},
			setStep(step: number) {
				setStep(step);
				return step;
			},
		}));

		const onBackClick = (steps?: number) => {
			const newStep = step - (steps ? steps : 1);
			if (newStep >= 0) {
				setStep(newStep);
				return newStep;
			} else return 0;
		};

		const onNextClick = (steps?: number) => {
			const newStep = step + (steps ? steps : 1);
			if (newStep <= props.views.length) {
				setStep(newStep);
				return newStep;
			} else return step;
		};

		return (
			<MainContainer>
				{!props.views[step].hideNavBar && (
					<NavBar $hideBackButton={props.views[step].hideBackButton}>
						{!props.views[step].hideBackButton && (
							<Button
								design="image"
								icon={<ArrowLeft />}
								disabled={step === 0}
								onClick={() => onBackClick()}
							/>
						)}
						{props.views[step].navigationRightView}
					</NavBar>
				)}
				<DataContainer style={props.views[step].containerStyle}>
					{props.views[step].leftView && (
						<LeftContainer style={props.views[step].leftViewStyle}>
							{props.views[step].leftView}
						</LeftContainer>
					)}
					{props.views[step].centralView && (
						<CentralContainer
							style={props.views[step].centralViewStyle}
						>
							{props.views[step].centralView}
						</CentralContainer>
					)}
					{props.views[step].rightView && (
						<RightContainer
							style={props.views[step].rightViewStyle}
						>
							{props.views[step].rightView}
						</RightContainer>
					)}
				</DataContainer>
			</MainContainer>
		);
	}
);
export default WizardContainer;
export interface WizardContainerProps {
	currentUser?: UserScheme;
	views: {
		containerStyle?: CSSProperties;
		leftViewStyle?: CSSProperties;
		leftView?: React.ReactNode;
		centralViewStyle?: CSSProperties;
		centralView?: React.ReactNode;
		rightViewStyle?: CSSProperties;
		rightView?: React.ReactNode;
		navigationRightView?: React.ReactNode;
		hideBackButton?: boolean;
		hideNavBar?: boolean;
	}[];
}
export interface WizardContainerRef {
	goBack: (steps?: number) => number;
	goNext: (steps?: number) => number;
	setStep: (step: number) => number;
}
