import { useState } from "react";
import CodeSection from "../../../components/section/Code";
import SectionContainer from "./SectionContainer";
import { Auth, ErrorScheme, UserScheme } from "client-v2";

const Code = ({ ...props }: CodeProps) => {
	const [loading, setLoading] = useState(false);

	const onNextClick = (code: string) => {
		if (props.user) {
			setLoading(true);
			Auth.login(props.user.username, code)
				.then((result) => {
					setLoading(false);
					localStorage.removeItem("temp-login");
					props.onFinish({ data: result.data });
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		}
	};

	return (
		<SectionContainer
			title="Código de verificación"
			subtitle={`Introduce el código que te hemos enviado por SMS${
				props.user ? " al " + props.user.phone : ""
			}.`}
			animated={props.animated}
		>
			<CodeSection
				{...props}
				onCodeChange={onNextClick}
				loading={loading}
			/>
		</SectionContainer>
	);
};
export default Code;
export interface CodeProps {
	user?: UserScheme;
	animated?: boolean;
	countdown?: number;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
